import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import upcoming_45global from "/usr/src/nuxt-app/middleware/upcoming.global.js";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  upcoming_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/nuxt-app/middleware/auth.js"),
  noauth: () => import("/usr/src/nuxt-app/middleware/noauth.js")
}