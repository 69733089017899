<template>
</template>

<script setup>
import { useAuthStore } from "~~/store/auth";
import { storeToRefs } from "pinia";

const router = useRouter();
const onLogin = () => {
  return router.currentRoute.value.name === "login";
};

const onPopup = () => {
  return router.currentRoute.value.path === "/integration" && typeof router.currentRoute.value.query.type !== "undefined";
};

const authStore = useAuthStore();
const { intercom, getUser } = storeToRefs(authStore);

const readyForIntercom = () => {
  return (
    intercom.value?.email === getUser?.value.email &&
    !onLogin() &&
    !onPopup() &&
    !document?.querySelector(".terms-of-service")
  );
};

const buildIntercom = () => {
  if (!readyForIntercom()) return;
  const { Intercom } = useScript("https://widget.intercom.io/widget/zz0osdbu", {
    use: () => ({ Intercom: window.Intercom })
  });
  Intercom("reattach_activator");
  Intercom("update", window.intercomSettings);
};

const prepareIntercom = async () => {
  if (onPopup()) return;
  await authStore.prepareIntercom();
}

onMounted(prepareIntercom);
watch(() => router.currentRoute.value.name, prepareIntercom);
watch(intercom, buildIntercom);
</script>
