import axios from "axios";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.apiURL;
  const router = useRouter();

  let api = axios.create({
    baseURL: baseUrl,
  });
  api.interceptors.response.use(
      (response) => response,
      (error) => {
          if (error?.response?.status === 401 && !router.currentRoute.value.path.match(/invoker|callback|forgot|landing/)) {
              router.push({ path: "/login" });
          }
          return Promise.reject(error);
      }
  );
  return {
    provide: {
      api: api,
    },
  };
});
