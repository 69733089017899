<template>
  <div>
    <div
      class="fixed inset-0 w-full h-full bg-black/60 backdrop-blur-sm z-[999999]"
      @click="$emit('showDeleteModal')"
    ></div>
    <div
      class="w-full max-w-[358px] md:max-w-[521px] h-fit bg-white dark:bg-neutral-800 dark:border-t-transparent border-t box-border border-neutral-200 md:p-8 p-6 fixed inset-0 m-auto z-[9999999] rounded"
    >
      <div class="flex flex-col gap-4">
        <p
          class="font-semibold text-2xl leading-8 text-neutral-800 dark:text-neutral-200 text-left"
        >
          Delete {{ title }}?
        </p>
        <div class="absolute md:top-6 md:right-6 top-4 right-4">
          <svg
            @click="$emit('showDeleteModal')"
            id="closeDeleteModal-icon"
            width="20"
            class="text-neutral-800 dark:text-white cursor-pointer"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="flex flex-col gap-8">
          <p
            class="font-normal text-base leading-normal text-neutral-600 dark:text-neutral-400 text-left"
          >
            Are you sure you want to delete this
            {{ mediaType === "niftyImages" ? "nifty image" : mediaType }}?
          </p>
          <div class="flex md:flex-row flex-col justify-end gap-2">
            <ReusableUIButtonsButtonTertiary
              size="medium"
              text="Cancel"
              id="closeDeleteModal-button"
              theme="gray"
              @click="$emit('showDeleteModal')"
            />
            <ReusableUIButtonsButtonPrimary
              size="medium"
              text="Delete"
              :loading="title == 'folder' ? folderLoading : loading"
              id="deleteMedia-button"
              width="md:w-[82px] w-full h-[42px]"
              theme="red"
              @click="title == 'folder' ? $emit('deleteFolder') : deleteMedia()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useMediaStore } from "~~/store/media";
import { useIntegrationStore } from "~~/store/integration";
const mediaStore = useMediaStore();
const integrationStore = useIntegrationStore();
const props = defineProps({
  mediaName: String,
  mediaType: String,
  deleteID: Number,
  deleteURL: String,
  title: String,
  getBackID: String,
  folderLoading: { type: Boolean, default: false },
});
const emit = defineEmits(["showDeleteModal", "error"]);
const loading = ref(false);

const deleteMedia = async () => {
  loading.value = true;
  if (props.mediaType === "niftyImages") {
    const response = await integrationStore.deleteNiftyImages(
      props.deleteURL,
      props.mediaName
    );
    if (response) {
      emit("showDeleteModal", true, props.getBackID);
    } else {
      loading.value = false;
    }
  } else {
    const response = await mediaStore.deleteMedia(
      props.deleteID,
      props.mediaType
    );
    if (response && !response?.error) {
      emit("showDeleteModal", true, props.getBackID);
    } else if (response?.error) {
      loading.value = false;
      emit("error", response.error);
    } else {
      loading.value = false;
    }
  }
  mediaStore.getFolders();
};
</script>
