import Unicon from "vue-unicons";
import {
  uniFolderPlus,
  uniEnvelope,
  uniTagAlt,
  uniCommentDots,
  uniFolderTimes,
  uniRefresh,
  uniWhatsapp,
  uniAirplay,
  uniUserTimes,
  uniExpandFromCorner,
  uniFolder,
  uniPause,
  uniFolderUpload,
  uniFacebookMessengerAlt,
  uniUserExclamation,
  uniAngleLeft,
  uniSchedule,
  uniExclamationTriangle,
  uniCalendarAlt,
  uniAngleLeftB,
  uniAngleRightB,
  uniLinkH,
  uniVoicemail,
  uniCommentsAlt,
  uniDollarSignAlt,
  uniCommentAltDots,
} from "vue-unicons/dist/icons";
Unicon.add([
  uniFolderPlus,
  uniEnvelope,
  uniTagAlt,
  uniCommentDots,
  uniFolderTimes,
  uniRefresh,
  uniWhatsapp,
  uniAirplay,
  uniUserTimes,
  uniExpandFromCorner,
  uniFolder,
  uniPause,
  uniFolderUpload,
  uniFacebookMessengerAlt,
  uniUserExclamation,
  uniAngleLeft,
  uniSchedule,
  uniExclamationTriangle,
  uniCalendarAlt,
  uniAngleLeftB,
  uniAngleRightB,
  uniLinkH,
  uniVoicemail,
  uniCommentsAlt,
  uniDollarSignAlt,
  uniCommentAltDots,
]);
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Unicon);
});
