import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import { useIntegrationStore } from "./integration";

export const useWebinarStore = defineStore({
  id: "webinar-store",
  state: () => {
    return {
      events: [],
      webinars: {},
      upcomingWebinars: {},
      latestUpcomingWebinars: {},
      pastWebinars: {},
      allPastWebinars: {},
      updatedPastWebinars: {},
      webinarStatus: {},
      totalPageCount: { upcoming: "0", past: "0", totalUpcomming: "0" },
      recurring: [],
      audienceTrack: [],
    };
  },
  actions: {
    async fetchWebinars() {
      const response = await call("/webinars", "GET");
      if (response) {
        const { success } = response;
        this.webinars = success;
      }
      return response;
    },
    async fetchUpcomingWebinars(page = 0, refresLatestWebinars = true) {
      const response = await call(
        `/webinars?page=${page}&type=upcoming`,
        "GET"
      );

      let upcomingWebinarsResponse = false;
      if (response) {
        const { success } = response;
        this.upcomingWebinars = success;
        this.totalPageCount.totalUpcomming = response.total;

        upcomingWebinarsResponse = this.upcomingWebinars;
        this.totalPageCount.upcoming = Math.ceil(response.total / 12);
      }
      if (refresLatestWebinars) {
        const responseforLatestEvents = await call(
          `/webinars?page=${0}&type=upcoming`,
          "GET"
        );

        if (responseforLatestEvents) {
          const { success } = responseforLatestEvents;
          this.latestUpcomingWebinars = success;
        }
      }
      return response;
    },
    async sortUpcomingWebinars(order, sortType, page = 0, tab) {
      const response = await call(
        `/webinars?sort=${sortType || "date"}&direction=${
          order || "asc"
        }&page=${page}&type=${tab}`,
        "GET"
      );

      if (tab == "upcoming") {
        let upcomingWebinarsResponse = false;
        if (response) {
          const { success } = response;
          this.upcomingWebinars = success;
          this.totalPageCount.totalUpcomming = response.total;

          upcomingWebinarsResponse = this.upcomingWebinars;
          this.totalPageCount.upcoming = Math.ceil(response.total / 12);
        } else if (tab == "past") {
          let pastWebinarsResponse = false;
          if (response) {
            const { success } = response;
            this.pastWebinars = success;
            this.totalPageCount.past = Math.ceil(response.total / 12);
            pastWebinarsResponse = this.pastWebinars;
          }
        }
      }
      return response;
    },
    async fetchFilteredUpcomingWebinars(webinarTimeline) {
      const response = await call(
        `/webinars?page=0&type=upcoming&wtl=${webinarTimeline}&count=false`,
        "GET"
      );
      let upcomingWebinarsResponse = false;
      if (response) {
        const { success } = response;
        this.upcomingWebinars = success;
        this.totalPageCount.past = Math.ceil(response.total / 12);
        upcomingWebinarsResponse = this.upcomingWebinars;
        // Object.assign(this.webinars, this.upcomingWebinars);
      }
      return upcomingWebinarsResponse;
    },
    async fetchPastWebinars(page = 0) {
      const response = await call(`/webinars?page=${page}&type=past`, "GET");
      let pastWebinarsResponse = false;
      if (response) {
        const { success } = response;
        this.pastWebinars = success;
        this.totalPageCount.past = Math.ceil(response.total / 12);
        pastWebinarsResponse = this.pastWebinars;
      }
      return response;
    },
    async fetchAllPastWebinars() {
      const response = await call(`/webinars?type=past`, "GET");
      let pastWebinarsResponse = false;
      if (response) {
        const { success } = response;
        this.allPastWebinars = success;
        pastWebinarsResponse = this.allPastWebinars;
      }
      return pastWebinarsResponse;
    },
    async fetchFilteredPastWebinars(webinarTimeline) {
      const response = await call(
        `/webinars?page=0&type=past&wtl=${webinarTimeline}&count=false`,
        "GET"
      );
      let pastWebinarsResponse = false;
      if (response) {
        const { success } = response;
        this.pastWebinars = Object.assign(this.pastWebinars, success);
        pastWebinarsResponse = this.pastWebinars;
        Object.assign(this.webinars, this.pastWebinars);
      }
      return pastWebinarsResponse;
    },
    async fetchWebinarStatus(payload) {
      const response = await call("/webinar/status", "POST", payload);
      return response;
    },
    async fetchWebinarAgents(webinarID) {
      const response = await call(`/webinars/${webinarID}/agents`, "GET");
      return response;
    },
    async fetchWebinarDetails(webinarID) {
      const response = await call(`/webinars/${webinarID}`, "GET");
      return response;
    },
    async fetchAnalyticsData(payload) {
      const response = await call(
        "/analytics/attendance",
        "POST",
        payload,
        true
      );
      if (
        response?.error?.response?.status === 524 ||
        typeof response?.registrants === "undefined"
      ) {
        return { error: "timeout" };
      }
      return response;
    },
    async scheduleWebinar(payload, edit = false) {
      const response = await call("/webinar/create", "POST", payload, true);

      let scheduleWebinarsResponse = {};
      // return response ? response : false;

      if (response?.success?.webinar && !response.error) {
        if (edit) {
          this.fetchUpcomingWebinars(0);
        } else if (response?.success?.webinar?.webinarID) {
          scheduleWebinarsResponse[response.success.webinar.webinarID] = [
            response.success.webinar,
          ];
          Object.assign(this.upcomingWebinars, scheduleWebinarsResponse);
        }
        this.fetchRecurring();
      } else if (typeof response.success != String) {
        this.fetchRecurring();
        return response;
      } else {
        return response;
      }
      return {
        ...scheduleWebinarsResponse,
        organizers: response?.success?.organizers,
        childEvents: response?.success?.childEvents,
      };
    },
    async deleteWebinar(payload, update) {
      const data = {
        webinarID: payload.webinarID,
        isRecurring: payload.isRecurring,
      };
      if (payload.uniqueID) data["uniqueID"] = payload.uniqueID;
      const formData = new FormData();
      Object.keys(data).map((key) => {
        formData.set(key, data[key]);
      });

      const response = await call("/webinar/delete", "POST", formData);
      if (response) {
        return response;
      }
    },
    async attachTimeline(integrationID, type) {
      let attachTimelineFormData = new FormData();
      attachTimelineFormData.append("integrationID", integrationID);
      attachTimelineFormData.append("type", type);
      const response = await call(
        "/webinar/upcoming",
        "POST",
        attachTimelineFormData
      );
      const { success } = response;
      return success;
    },
    async updateWebinarVisibility(webinarID, value) {
      let status = JSON.stringify({ visible: value });
      let updateVisibilityPayload = new FormData();
      updateVisibilityPayload.append("webinarID", webinarID);
      updateVisibilityPayload.append("blocks", status);
      const response = await call(
        "/webinar/update",
        "POST",
        updateVisibilityPayload
      );
      const { success } = response;
      return success;
    },
    async addCoOrganizer(settings) {
      let formData = new FormData();
      formData.append("webinarId", settings.webinarId);
      formData.append("tenantId", settings.tenantId);
      formData.append("name", settings.name);
      formData.append("email", settings.email);

      const response = await call(
        "/stream/webinar/coorganizer",
        "POST",
        formData
      );
      if (response) {
      }
      return response;
    },
    async getAlternativeHosts(webinarID) {
      const response = await call("/webinars/" + webinarID + "/hosts", "GET");
      if (response) {
      }
      return response;
    },
    async exportWebinar(type, webinarID) {
      let exportWebinarFormData = new FormData();
      exportWebinarFormData.append("webinarID", webinarID);
      exportWebinarFormData.append("type", type);
      const response = await call(
        `/webinar/export?type=${type}&webinarID=${webinarID}`,
        "GET",
        exportWebinarFormData
      );
      return response;
    },

    async fetchRecurring() {
      let platform = "evergreen";
      const response = await call(`recurring?platform=${platform}`, "GET");
      if (response?.success) {
        this.recurring = response.success;
      }
      return response;
    },

    async fetchFilteredRecurring(params,direct,sort,platform) {
      const response = await call(`recurring?${params?`wtl=${params}`:`platform=${platform}&sort=${sort}&direction=${direct}`}`, "GET");
      if (response) {
        this.recurring = response;
      }
      return response;
    },

    async deleteRecurring(recurringID) {
      return await call(`recurring/${recurringID}`, "DELETE");
    },
    // here to filter
    async filterWebinar(timelineID, type, page) {
      const response = await call(
        `webinars?wtl=${timelineID}&type=${type}&page=${page}`,
        "GET"
      );
      if (response && type == "upcoming") {
        let upcomingWebinarsResponse = false;
        const { success } = response;
        this.upcomingWebinars = success;
        this.totalPageCount.upcoming = Math.ceil(response.total / 12);
        upcomingWebinarsResponse = this.upcomingWebinars;
        return upcomingWebinarsResponse;
      } else if (response && type == "past") {
        let pastWebinarsResponse = false;
        const { success } = response;
        this.pastWebinars = success;
        this.totalPageCount.past = Math.ceil(response.total / 12);
        pastWebinarsResponse = this.pastWebinars;
        return pastWebinarsResponse;
      }
      return response;
    },

    async getAudience(startDate, endDate) {
      let response = await call("/analytics/breakdown", "POST", {
        endDate: endDate,
        startDate: startDate
      });

      if (response) {
        this.audienceTrack = response;
        return response;
      }
    },
  },
  getters: {
    eventsList: (state) => state.events,
    webinarsList: (state) => state.webinars,
    upcomingWebinarsList: (state) => state.upcomingWebinars,
    latestWebinarsList: (state) => state.latestUpcomingWebinars,
    pastWebinarsList: (state) => state.pastWebinars,
    webinarStatusList: (state) => state.webinarStatus,
    totalPages: (state) => state.totalPageCount,
    audienceTrackData: (state) => state.audienceTrack,
    deliveryPlatforms: (state) => {
      const authStore = useAuthStore();
      const integrationStore = useIntegrationStore();
      const userInfo = authStore.getUser;
      const platforms = {};
      if (Object.keys(integrationStore.integrationsList).length > 0) {
        const integrations = integrationStore.integrationsList;
        const hasGTW =
          Object.values(integrations).filter(
            (integration) => integration.type == "GOTOWEBINAR"
          ).length > 0;
        const hasZoom =
          Object.values(integrations).filter(
            (integration) => integration.type == "ZOOM"
          ).length > 0;

        const hasVSL =
          Object.values(integrations).filter(
            (integration) => integration.type == "VSL"
          ).length > 0;
        const hasYouTube =
          Object.values(integrations).filter(
            (integration) => integration.type == "YOUTUBE"
          ).length > 0;
        if (hasGTW) {
          platforms["gotowebinar"] = {
            title: "GotoWebinar",
            value: "gotowebinar",
          };
        }
        if (hasZoom) {
          platforms["zoom"] = {
            title: "Zoom",
            value: "zoom",
          };
        }
        if (hasYouTube) {
          platforms["youtube"] = {
            title: "YouTube",
            value: "youtube",
          };
        }
        platforms["aevent"] = {
          title: "AEvent",
          value: "aevent",
        };
        // if (userInfo?.beta == 1) {
        //   platforms["youtube"] = {
        //     title: "YouTube",
        //     value: "youtube",
        //   };
        // }
        if (hasVSL) {
          platforms["vsl"] = {
            title: "AVideo",
            value: "vsl",
          };
        }
        // if (Object.keys(platforms).length == 0) {
        //   platforms["error"] = {
        //     name: "Please Create an Integration",
        //     disabled: true,
        //   };
        // }
      } else {
        platforms["aevent"] = {
          title: "AEvent",
          value: "aevent",
        };
      }
      return platforms;
    },
    recurringList: (state) => state?.recurring ?? {},
  },
});
