import { defineStore } from "pinia";
import { useMediaStore } from "./media";
import { useIntegrationStore } from "./integration";
import { useSettingsStore } from "./settings";
import { useWebinarStore } from "./webinar";
// impo
export const useTimelineStore = defineStore({
  id: "timeline-store",
  state: () => {
    return {
      timelines: {},
      trashTimelines: {},
      timelineNodes: {},
      templates: {},
      revisions: {},
      templateNodes: {},
      templatesForms: {},
    };
  },
  actions: {
    async createTimeline(data) {
      // Before create add an api call for template integrations and stuff
      const payload = new FormData();
      payload.append("blocks", JSON.stringify(data));
      const response = await call("/generalsetting/create", "POST", payload);
      if (response) {
        const getTimelines = await this.fetchTimelinesMaster();
        if (getTimelines) {
          const { success } = getTimelines;
          this.timelines = success;
        }
      }
      return response;
    },
    async setTimelineNodes(data, webinar, initializeTemplate) {
      // Before create add an api call for template integrations and stuff
      const convertedPayload = new FormData();
      convertedPayload.append("blocks", JSON.stringify(data));
      convertedPayload.append("webinarTimeline", webinar);
      convertedPayload.append("isPast", true);
      convertedPayload.append("initializeTemplate", initializeTemplate);
      const response = await call(
        "/webinartimelineevent/set",
        "POST",
        convertedPayload
      );
      return response;
    },

    async fetchTimelinesMaster() {
      const response = await call("/webinartimelinemaster/get", "POST");
      if (response) {
        const { success } = response;
        this.timelines = success;
      }
      return response;
    },
    async fetchTimelinesTrashed() {
      const response = await call(
        "/webinartimelinemaster/get?filter=trash",
        "POST"
      );
      if (response) {
        const { success } = response;
        this.trashTimelines = success;
      }
      return response;
    },
    async restoreTimelineTrashed(id) {
      const response = await call("/webinartimelinemaster/restore", "POST", {
        webinarTimeline: id,
      });
      if (response) {
        this.fetchTimelinesMaster();
      }
      return response;
    },
    async fetchShareTimelines(id) {
      let shareResponse = "";
      const response = await call("/webinartimelinemaster/share", "POST", id);
      if (response) {
        const { success } = response;
        shareResponse = success;
      }
      return shareResponse;
    },
    async copyTimeline(id, name) {
      let copyResponse = {};
      let formData = new FormData();
      formData.append("webinarTimeline", id);
      formData.append("timelineName", name);
      // formData.append("findAndReplace", {});
      const response = await call(
        "/webinartimelinemaster/copy",
        "POST",
        formData
      );
      if (response) {
        const { success } = response;
        // this.timelines[success.webinarTimeline] = success;
        // copyResponse = this.timelines;
        // here is changed response
        const respons = await call("/webinartimelinemaster/get", "POST");
        if (response) {
          const { success } = respons;
          this.timelines = success;
        }
      }
      return copyResponse;
    },

    async getWizardActionEvents(template, webinarTimelines) {
      let fakeDatastore = {
        webinars: {},
        globalSetting: {},
        projects: null,
        projectData: {},
        integrations: null,
        videos: null,
        audios: null,
        images: null,
        servers: {},
        wizard: {
          templates: {
            actions: null,
          },
          actions: {},
        },
        resource_status: {
          actions: 0,
          webinar_hours: 0,
        },
      };
      let sRet = fakeDatastore.wizard.actions?.[template];
      if (sRet) return Promise.resolve(sRet);
      let formData = new FormData();
      formData.append("webinarTimelines", JSON.stringify(webinarTimelines));
      const response = await call(
        "/wizard/templates/" + template,
        "POST",
        formData
      );
      if (response) {
        if (fakeDatastore?.wizard?.actions) {
          fakeDatastore.wizard.actions[template] = response.success;
        } else {
          fakeDatastore.wizard.actions = {};
          fakeDatastore.wizard.actions[template] = response.success;
        }
        return response.success;
      }
    },

    async fetchTemplateNodes(urlParam, integrations) {
      let payload = new FormData();
      payload.append("timelineNodes", true);
      payload.append("webinarTimelines", integrations);

      const response = await call(
        `/wizard/templates/${urlParam}`,
        "POST",
        payload
      );

      if (response.success && response.success) {
        const { success } = response;
        this.templateNodes = success;
      }
    },
    async fetchTemplateEvents(type, integrations) {
      let payload = new FormData();

      payload.append("webinarTimelines", integrations);

      const response = await call(`/wizard/templates/${type}`, "POST", payload);
      return response;
    },
    async fetchTimelineNodes(timelineID) {
      const payload = {
        webinarTimeline: timelineID,
      };
      const response = await call("/webinartimelineevent/get", "POST", payload);

      if (response.success && response.success) {
        const { success } = response;

        let templateNodes = success;
        // let templateNodes = success;
        if (typeof templateNodes === "string") {
          templateNodes = JSON.parse(templateNodes);
        }

        if (templateNodes.length == 0) {
          this.timelineNodes = JSON.parse(
            JSON.stringify(DEFAULT_TIMELINE_NODES)
          );
        } else {
          Object.keys(DEFAULT_TIMELINE_NODES).map((key) => {
            if (!templateNodes[key]) {
              templateNodes[key] = JSON.parse(
                JSON.stringify(DEFAULT_TIMELINE_NODES[key])
              );
            }
          });

          this.timelineNodes = templateNodes;
          return success;
        }
      }
    },
    async getCalData(
      webinars,
      timelineID,
      time,
      checkMultiEvents,
      multievents
    ) {
      const config = useRuntimeConfig();

      const cdnURL = config.public.cdnURL;

      const arr = [];

      Object.values(this.timelineNodes)
        .filter((node) => node && node.length > 0)
        .forEach((node, key) => {
          // Here we were handling old UI values now no need

          // for (let i = 0; i < node?.length; i++) {
          //   if (node[i]) {
          //     if (!checkMultiEvents && node[i]?.model) {
          //       node[i].model.fromEventID = [];
          //     } else {
          //       let multiEventID = Object.keys(multievents);
          //       if (Array.isArray(node[i].model.fromEventID)) {
          //         node[i].model.fromEventID = node[
          //           i
          //         ]?.model?.fromEventID?.filter(
          //           (item) => item == "general" || multiEventID.includes(item)
          //         );
          //       }
          //     }

          //     if (checkMultiEvents && node[i].model?.fromEventID?.length == 0) {
          //       node[i].model.fromEventID = ["general"];
          //     }
          //   }
          // }
          node.forEach((innerNode) => {
            const date = new Date(time);
            const checkKey = parseInt(innerNode.key);
            const days = parseInt(innerNode.days);
            if (innerNode.hours == null || innerNode.hours == "") {
              innerNode.hours = "00";
            } else if (innerNode.minutes == null || innerNode.minutes == "") {
              innerNode.minutes = "00";
            }

            let relativeTimeStr = `${days} day`;

            if (checkKey < 0) {
              date.setDate(date.getDate() - parseInt(days));
            } else if (checkKey > 0) {
              date.setDate(date.getDate() + parseInt(days));
            } else {
              relativeTimeStr = "";
            }

            if (innerNode.isExact) {
              date.setHours(0, 0, 0, 0);
            }
            if (innerNode.hours !== "" && innerNode.hours !== null) {
              const adjustedHours = innerNode.isExact
                ? parseInt(innerNode.amPm) === 1
                  ? parseInt(innerNode.hours) === 12
                    ? 12 // If it's 12 AM, set hours to 0
                    : parseInt(innerNode.hours) + 12
                  : parseInt(innerNode.hours) == 12
                  ? 0 // If it's 12 PM, set hours to 12
                  : innerNode.hours
                : parseInt(innerNode.isAfter) === 1
                ? date.getHours() + parseInt(innerNode.hours)
                : date.getHours() - parseInt(innerNode.hours) - 1;
              date.setHours(Math.max(0, Math.min(23, adjustedHours)));
              if (innerNode.hours !== "00") {
                relativeTimeStr += " " + innerNode.hours + " HR";
              }
            }

            // date.setHours(Math.max(0, Math.min(23, adjustedHours)));

            if (innerNode.minutes !== "" && innerNode.minutes !== null) {
              const adjustedMinutes = innerNode.isExact
                ? parseInt(innerNode.minutes)
                : parseInt(innerNode.isAfter) === 1
                ? date.getMinutes() + parseInt(innerNode.minutes)
                : date.getMinutes() -
                  parseInt(innerNode.minutes == 0 ? 1 : innerNode.minutes);

              // Ensure adjustedMinutes is within the valid range (0 to 59)
              date.setMinutes(Math.max(0, Math.min(59, adjustedMinutes)));

              if (innerNode.minutes != "00") {
                relativeTimeStr +=
                  " " +
                  innerNode.minutes +
                  `${parseInt(innerNode.minutes) == 1 ? " min" : " mins"}`;
              }
            }

            let description = getActionDescription(
              innerNode.action,
              innerNode.model
            );
            const obj = {
              title: `- ${innerNode.actionTitle}`,
              start: date,
              overlap: false,
              image: `${cdnURL}${
                innerNode.integrationType == "AUTOMA8"
                  ? "aevent"
                  : innerNode.integrationType
              }.png`,
              display: "month",
              actionIcons: innerNode.actionIcon,
              eventId: innerNode.id,
              model: innerNode.model,
              action: innerNode.action,
              integrationName: innerNode.integrationName,
              integrationType: innerNode.integrationType,
              integrationID: innerNode.integrationID || null,
              description: description && description,
              media: getActionMedia(innerNode.action, innerNode.model),
              // key: innerNode.key,
              key: innerNode.key < 0 ? innerNode.key - 1 : innerNode.key,
              relativeTime:
                innerNode.isAfter == 1
                  ? `${relativeTimeStr ? relativeTimeStr : "0Min "} after`
                  : `${relativeTimeStr ? relativeTimeStr : "0Min "} before`,
              isExact: innerNode.isExact,
              days: days,
              groupName: innerNode.groupName,
              currentDate: date,
              completeNode: innerNode,
              fromEventID: innerNode?.fromEventID || null,
              fromEventName: innerNode?.model?.fromEventName || null,
              backgroundColor: "green",
              checkValidate: !validateNewAction(
                innerNode,
                innerNode.integrationType
              ),
            };
            arr.push(obj);
          });
        });
      return arr;
    },
    async checkIfTimelineUsed(timeline) {
      const payload = {
        webinarTimeline: timeline,
      };
      const checkResponse = await call(
        "webinartimeline/check_if_used",
        "POST",
        payload
      );
      if (checkResponse) {
        const { success } = checkResponse;
        return success;
      }
      // if (!checkResponse) return false;
    },
    async deleteTimeline(timeline) {
      const payload = {
        webinarTimeline: timeline,
      };
      const delResponse = await call(
        "/webinartimeline/delete",
        "POST",
        payload,
        true
      );

      if (!delResponse) return false;
      if (delResponse.error?.response?.data?.message) {
        return { error: delResponse.error.response.data.message };
      }

      const timelines = this.timelines;
      delete timelines[payload.webinarTimeline];
      this.timelines = timelines;
      const webinarStore = useWebinarStore();
      webinarStore.fetchUpcomingWebinars(0, false);
      webinarStore.fetchRecurring();
      return delResponse;
    },
    async setTimelineStatus(id, setting, key, value) {
      const payload = {
        webinarTimeline: id,
        setting: setting,
        key: key,
        value: JSON.stringify(value),
      };
      const response = await call(
        "webinartimelinemaster/set/item",
        "POST",
        payload
      );
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async fetchTemplates(params) {
      let formData = new FormData();
      formData.append("search", params);

      const response = await call(
        `/wizard/templates`,
        "POST",
        params != undefined && formData
      );
      if (response.success && response.success) {
        const { success } = response;
        this.templates = success;
        return success;
      }
      return this.templates;
    },
    async getActionsByCode(code) {
      let formData = new FormData();
      formData.append("code", code);
      const response = await call(
        "webinartimelineevent/get/code",
        "POST",
        formData
      );
      if (response.success) {
        return response.success;
      }

      return response;
    },
    async fetchRevisions(id) {
      const response = await call(`/timelines/${id}/revisions`, "GET");

      if (response.success && response.success) {
        const { success } = response;
        this.revisions = success;
        return success;
      }
      return this.revisions;
    },
    async uploadAutochatCSV(settings) {
      const blob = settings.file;

      const data = {
        webinarTimeline: settings.webinarTimeline,
        groups: settings.groups,
        id: settings.id,
        eventID: settings.eventID,
      };
      const formData = new FormData();
      Object.keys(data).map((key) => {
        formData.set(key, data[key]);
      });
      formData.set("csv", blob);

      const nuxtAppInstance = useNuxtApp();
      const config = useRuntimeConfig();
      const apiURL = config.public.apiURL;
      return nuxtAppInstance.$api
        .post(`${apiURL}/webinartimelineevent/autocsv`, formData, {
          cancelToken: this.cancelToken,
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${useCookie("token").value}`,
          },
        })
        .then((response) => {
          if (response) {
            return response;
          }
        })
        .catch((error) => {
          console.error(error, "error");
        });
    },
    async uploadRegistrantCSV({ webinarTimeline, file }) {
      const data = {
        webinarTimeline,
      };
      const formData = new FormData();
      Object.keys(data).map((key) => {
        formData.set(key, data[key]);
      });
      formData.set("csv", file);

      const nuxtAppInstance = useNuxtApp();
      const config = useRuntimeConfig();
      const apiURL = config.public.apiURL;
      return nuxtAppInstance.$api
        .post(`${apiURL}/registrants`, formData, {
          cancelToken: this.cancelToken,
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${useCookie("token").value}`,
          },
        })
        .then((response) => {
          if (response) {
            return response;
          }
        })
        .catch((error) => {
          return { error: error?.response?.data?.message };
        });
    },
    async filterTemplateNodes(integration) {
      return new Promise((resolve, reject) => {
        const filteredNodes = Object.fromEntries(
          Object.entries(this.templateNodes).map(([key, value]) => {
            if (!Array.isArray(value)) {
              delete this.templateNodes[key];
              return [key, []]; // Empty array as the new value
            }

            return [key, integration[key] || []];
          })
        );
        resolve(filteredNodes);
      });
    },
    async saveNewAction(
      integrationActionModel,
      key,
      webinarTimeline,
      isEdit,
      duplicate,
      oldID,
      cancel
    ) {
      if (isEdit && !duplicate) {
        if (
          !this.timelineNodes[key] ||
          !Array.isArray(this.timelineNodes[key])
        ) {
          this.timelineNodes[key] = [];
        }
        const route = useRoute();
        this.deleteTimelineNode(oldID, route.params.id);
        let newNode = JSON.parse(JSON.stringify(integrationActionModel));

        this.timelineNodes[key].push(newNode);
      } else {
        if (!Array.isArray(this.timelineNodes[key])) {
          this.timelineNodes[key] = [];
        }
        this.timelineNodes[key].push(integrationActionModel);
      }
      // Send API call to save nodes

      const payload = new FormData();
      payload.append("blocks", JSON.stringify(this.timelineNodes));
      payload.append("webinarTimeline", webinarTimeline);
      payload.append("isPast", true);
      if (cancel) return true;
      const response = await call("/webinartimelineevent/set", "POST", payload);
      return response;
    },
    async deleteTimelineNode(id, timelineID) {
      // Loop through each key in the data object
      for (const key in this.timelineNodes) {
        this.timelineNodes[key] = Object.values(
          this.timelineNodes[key]
        )?.filter((item) => item.id != id);
      }
    },
    async copySetting({ copyFrom, copyTo, setting }) {
      await call("webinartimelinemaster/copysetting", "PUT", {
        copyFrom,
        copyTo,
        setting,
      });
    },
    async getTemplateForms() {
      let response = await call("forms", "GET");
      if (response) {
        this.templatesForms = response?.success;
      }
      return response;
    },
    async createTemplateForm(payload) {
      let response = await call("forms", "POST", payload);
      if (response) {
        this.getTemplateForms();
      }
      return response;
    },
    async deleteTemplateForm(id) {
      let response = await call(`forms/${id}`, "DELETE", id);
      if (response.success) {
        let filteredForms = Object.values(this.templatesForms).filter(
          (item) => item.id != id
        );
        this.templatesForms = filteredForms;
      }
      return response;
    },
    async updateTemplateForm(id, payload) {
      let response = await call(`forms/${id}`, "PUT", payload);
      if (response?.success) {
        let updatedTemplate = response.success;
        Object.entries(this.templatesForms).forEach(([key, item]) => {
          if (item.id === id) {
            this.templatesForms[key] = updatedTemplate;
          }
        });
      }
      return response;
    },
  },
  getters: {
    timelinesList: (state) => state.timelines,
    trashedList: (state) => state.trashTimelines,
    getTimelineNodes: (state) => state.timelineNodes,
    getTemplates: (state) => state.templates,
    revisionsList: (state) => state.revisions,
    activeNodeList: (state) => state.templateNodes,
    timelinesCount: (state) => Object.keys(state?.timelines ?? [])?.length ?? 0,
    getForms: (state) => state.templatesForms,
  },
});

export const DEFAULT_TIMELINE_NODES = {
  "-45": [],
  "-44": [],
  "-43": [],
  "-42": [],
  "-41": [],
  "-40": [],
  "-39": [],
  "-38": [],
  "-37": [],
  "-36": [],
  "-35": [],
  "-34": [],
  "-33": [],
  "-32": [],
  "-31": [],
  "-30": [],
  "-29": [],
  "-28": [],
  "-27": [],
  "-26": [],
  "-25": [],
  "-24": [],
  "-23": [],
  "-22": [],
  "-21": [],
  "-20": [],
  "-19": [],
  "-18": [],
  "-17": [],
  "-16": [],
  "-15": [],
  "-14": [],
  "-13": [],
  "-12": [],
  "-11": [],
  "-10": [],
  "-9": [],
  "-8": [],
  "-7": [],
  "-6": [],
  "-5": [],
  "-4": [],
  "-3": [],
  "-2": [],
  "-1": [],
  0: [],
  "+1": [],
  "+2": [],
  "+3": [],
  "+4": [],
  "+5": [],
  "+6": [],
  "+7": [],
  "+8": [],
  "+9": [],
  "+10": [],
  "+11": [],
  "+12": [],
  "+13": [],
  "+14": [],
  "+15": [],
  "+16": [],
  "+17": [],
  "+18": [],
  "+19": [],
  "+20": [],
  "+21": [],
  "+22": [],
  "+23": [],
  "+24": [],
  "+25": [],
  "+26": [],
  "+27": [],
  "+28": [],
  "+29": [],
  "+30": [],
  "+31": [],
  "+32": [],
  "+33": [],
  "+34": [],
  "+35": [],
  "+36": [],
  "+37": [],
  "+38": [],
  "+39": [],
  "+40": [],
  "+41": [],
  "+42": [],
  "+43": [],
  "+44": [],
  "+45": [],
};

export const getActionDescription = (integrationAction, model) => {
  // here we are getting the description of the integration action
  if (!integrationAction) return "";
  const action = integrationAction?.split("-");
  const checkAction = action[1] ? action[1] : action[0];
  switch (checkAction) {
    case "removeTag":
    case "tag-id":
    case "addTag":
      return model && model["tagName"]
        ? model["tagName"]
        : model["tag-name"]
        ? model["tag-name"]
        : "";
    case "addToCampaign":
    case "pauseCampaign":
    case "subscribeToCampaign":
    case "removeFromCampaign":
      return model && model["campaignName"] ? model["campaignName"] : "";
    case "addToList":
    case "removeFromList":
      return model && model["listName"] ? model["listName"] : "";
    case "sendSMS":
    case "sendsms":
    case "textuser":
      return model && model["sms-text"] ? model["sms-text"] : "";
    case "sendmms":
    case "sendMMS":
      return model && model["mms-text"] ? model["mms-text"] : "";
    case "sendText":
    case "sendMediaMsg":
      return model && model["text"] ? model["text"] : "";
    case "webhook":
      return model && model["address"] ? model["address"] : "";
    case "moveList":
      return model && model["toListName"]
        ? model["toListName"] +
            "&fromList" +
            (model && model["listName"] && model["listName"] != ""
              ? model["listName"]
              : model["fromListName"])
        : "";
    case "sendWhatsAppTemplate":
      return model && model["template"] ? model["template"] : "";
    case "livechat":
      return model && model["message"] ? model["message"] : "";
    case "addGoal":
      return model && model["goalName"] ? model["goalName"] : "";
    case "moveToList":
      return model && model["listName"] ? model["listName"] : "";
    case "sendrvm":
    case "vmmsg":
      return model && model["filename"] ? model["filename"] : "";
    case "sendWebhook":
      return model && model["webhook"] ? model["webhook"] : "";
    case "removeFromAutomation":
    case "addToAutomation":
      return model && model["automationName"] ? model["automationName"] : "";
    case "removeSubscriber":
      return model && model["client"]
        ? model["list"] + "&fromList" + model["client"]
        : "";
    case "manychatwhatsapp":
    case "sendWhatsappTemplate":
      return model && model["flowName"] ? model["flowName"] : "";
    case "addToWorkflow":
    case "removeFromWorkflow":
    case "startWorkflow":
      return model && model["workflowName"] ? model["workflowName"] : "";

    case "addToAudience":
    case "removeFromAudience":
      return model && model["audienceName"] ? model["audienceName"] : "";
  }
};

export const getActionMedia = (integrationAction, model) => {
  if (!integrationAction) return null;
  const action = integrationAction.split("-");
  if (!action[1]) return action[0];
  if (
    !action[1].toLowerCase().includes("vmmsg") &&
    !action[1].toLowerCase().includes("media") &&
    !action[1].toLowerCase().includes("mms")
  )
    return null;

  switch (model.type) {
    case "image":
      return {
        type: model.type,
        url: model.imagefile,
      };
    case "mpeg":
    case "mp3":
      return {
        type: model.type,
        url: model.audiofile,
      };
  }
};

export const getEndOfDay = (dateStr) => {
  const date = new Date(dateStr);
  date.setHours(23, 59, 59, 999); // Set end time to one second before midnight
  return date.toISOString();
};

export const validateNewAction = (
  integrationActionModel,
  integration,
  integrationList
) => {
  const { minutes, hours, groupID, action, isExact, integrationID, isAfter } =
    integrationActionModel;
  const integrationStore = useIntegrationStore();

  const hasValidHoursAndMinutes = isExact
    ? !(minutes == null && hours == null)
    : !(minutes == null && hours == null);
  if (isAfter == "-1") {
    if (!action || !hasValidHoursAndMinutes) {
      return false;
    }
  } else {
    if (!groupID || !action || !hasValidHoursAndMinutes) {
      return false;
    }
  }

  const model = integrationActionModel.model;

  let integration_list = integrationStore.integrationsList[integrationID];
  let integrationType = integration?.toLowerCase();
  switch (integrationType) {
    case "twilio":
      if (action === "twilio-textuser") {
        return !!(model["sms-text"] && model.phonenumber);
      } else if (action === "twilio-sendmms") {
        return !!(model["type"] && model["image"]);
      }
      break;
    case "manychatwhatsapp":
      if (action === "manychatwhatsapp-sendWhatsappTemplate") {
        return !!model["flowID"];
      }
      break;

    case "manychat":
      const manychatTagActions = ["manychat-addTag", "manychat-removeTag"];
      if (manychatTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (action === "manychat-sendText") {
        return !!model["text"];
      } else if (action === "manychat-sendMediaMsg") {
        return !!(model["type"] && model["file"]);
      }
      break;
    case "getresponse":
      if (action === "getresponse-addTag") {
        return !!(
          model["tag-id"] &&
          formatTLIntegrationData(
            integration_list?.tags || {},
            "optionsGetResponse"
          )[model["tag-id"]]
        );
      } else if (action === "getresponse-moveList") {
        return !!(
          model["to-listID"] &&
          formatTLIntegrationData(
            integration_list?.lists || {},
            "optionsGetResponse"
          )[model["to-listID"]]
        );
      }
      break;
    case "infusionsoft":
      const infusionsoftTagActions = [
        "infusionsoft-addTag",
        "infusionsoft-removeTag",
      ];
      const infusionsoftCampaignActions = [
        "infusionsoft-subscribeToCampaign",
        "infusionsoft-removeFromCampaign",
      ];
      if (infusionsoftTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (infusionsoftCampaignActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.lists || {})[
            model["campaignID"]
          ]
        );
      }
      break;
    case "drip":
      const dripTagActions = ["drip-addTag", "drip-removeTag"];
      const dripCampaignActions = [
        "drip-addToCampaign",
        "drip-pauseCampaign",
        "drip-removeFromCampaign",
      ];
      const dripWorkflowActions = [
        "drip-startWorkflow",
        "drip-removeFromWorkflow",
      ];

      if (dripTagActions.includes(action)) {
        return !!(
          model["tag-name"] &&
          formatTLIntegrationData(integration_list?.tags || {}, "headers")[
            model["tag-name"]
          ]
        );
      } else if (dripCampaignActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      } else if (dripWorkflowActions.includes(action)) {
        return !!(
          model["workflowID"] &&
          formatIntegrationsData(integration_list?.workflows || {})[
            model["workflowID"]
          ]
        );
      }
      break;
    case "ontraport":
      const ontraportTagActions = ["ontraport-addTag", "ontraport-removeTag"];
      const ontraportCampaignActions = [
        "ontraport-subscribeToCampaign",
        "ontraport-pauseCampaign",
        "ontraport-removeFromCampaign",
      ];

      if (ontraportTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatOntraPortData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (ontraportCampaignActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatOntraPortData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      }
      break;
    case "maropost":
      const maropostTagActions = ["maropost-addTag", "maropost-removeTag"];
      if (maropostTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatMaroPostData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (action === "maropost-moveList") {
        return !!(
          model["to-listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[
            model["to-listID"]
          ]
        );
      }
      break;
    case "hubspot":
      const hubspotWorkflowActions = [
        "hubspot-addToWorkflow",
        "hubspot-removeFromWorkflow",
      ];
      const hubspotListActions = [
        "hubspot-addToList",
        "hubspot-removeFromList",
      ];
      if (hubspotWorkflowActions.includes(action)) {
        return !!(
          model["workflowID"] &&
          formatIntegrationsData(integration_list?.workflows || {})[
            model["workflowID"]
          ]
        );
      } else if (hubspotListActions.includes(action)) {
        return !!(
          model["listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["listID"]]
        );
      }
      break;
    case "gohighlevel":
      const goghighlevelTagActions = [
        "gohighlevel-addTag",
        "gohighlevel-removeTag",
      ];
      const goghighlevelCampaignActions = [
        "gohighlevel-subscribeToCampaign",
        "gohighlevel-removeFromCampaign",
      ];
      if (goghighlevelTagActions.includes(action)) {
        return !!(
          model["tagName"] &&
          formatTLIntegrationData(
            integration_list?.tags || {},
            "optionsWithBothNames"
          )[model["tagName"]]
        );
      } else if (goghighlevelCampaignActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      } else if (action === "gohighlevel-addToWorkflow") {
        return !!(
          model["workflowID"] &&
          formatIntegrationsData(integration_list?.workflows || {})[
            model["workflowID"]
          ]
        );
      }
      break;
    case "constantcontact":
      const constantcontactTagActions = [
        "constantcontact-addTag",
        "constantcontact-removeTag",
      ];
      const constantcontactListActions = [
        "constantcontact-addToList",
        "constantcontact-removeFromList",
      ];
      if (constantcontactTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (constantcontactListActions.includes(action)) {
        return !!(
          model["listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["listID"]]
        );
      }
      break;
    case "dropcowboy":
      if (action === "dropcowboy-sendsms") {
        return !!(
          model["sms-text"] &&
          model["replyto"] &&
          model["poolID"] &&
          formatIntegrationsData(integration_list?.numberpools || {})[
            model["poolID"]
          ]
        );
      } else if (action === "dropcowboy-sendrvm") {
        return !!(
          integrationActionModel["audiofile"] &&
          model["replyto"] &&
          model["poolID"] &&
          formatIntegrationsData(integration_list?.numberpools || {})[
            model["poolID"]
          ]
        );
      }
      break;
    // case "sendlane":
    //   const sendlaneTagActions = ["sendlane-addTag", "sendlane-removeTag"];
    //   if (sendlaneTagActions.includes(action)) {
    //     return !!(
    //       model["tagName"] &&
    //       formatIntegrationsDataSendlane(integration_list?.tags || {})[
    //         model["tagName"]
    //       ]
    //     );
    //   } else if (sendlaneTagActions.includes(action)) {
    //     return !!(
    //       model["toList"] &&
    //       formatIntegrationsDataSendlane(integration_list?.lists || {})[
    //         model["toList"]
    //       ]
    //     );
    //   }
    //   break;
    case "kartra":
      const kartraTagActions = ["kartra-addTag", "kartra-removeTag"];
      const kartraSequenceActions = [
        "kartra-subscribeToSequence",
        "kartra-removeFromSequence",
      ];

      if (kartraTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatKartraData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (kartraSequenceActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatKartraData(integration_list?.sequences || {})[
            model["campaignID"]
          ]
        );
      }
      break;
    // ,
    //   "icontact-sendEmail",
    //   "icontact-deleteSubscriber",
    case "icontact":
      const icontactListActions = ["icontact-moveToList"];
      if (icontactListActions.includes(action)) {
        return !!model["listID"];
      }
      break;
    case "slicktext":
      if (action === "slicktext-sendsms") {
        return !!model["sms-text"];
      } else if (action === "slicktext-sendmms") {
        return !!(model["type"] && model["image"] && model["mms-text"]);
      }
      break;
    case "iterable":
      const iterableListActions = [
        "iterable-addToList",
        "iterable-removeFromList",
      ];
      if (action === "iterable-sendCampaign") {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      } else if (iterableListActions.includes(action)) {
        return !!(
          model["listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["listID"]]
        );
      }
      break;
    case "convertkit":
      const convertkitTagActions = [
        "convertkit-addTag",
        "convertkit-removeTag",
      ];
      const convertkitCampaignActions = [
        "convertkit-subscribeToCampaign",
        "convertkit-removeFromCampaign",
      ];
      if (convertkitTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatTLIntegrationData(
            integration_list?.tags || {},
            "optionsWithBothNames"
          )[model["tagID"]]
        );
      } else if (convertkitCampaignActions.includes(action)) {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      }
      break;
    case "roezan":
      const roezanTagActions = ["roezan-addTag", "roezan-removeTag"];
      const roezanListActions = ["roezan-addToList", "roezan-removeFromList"];
      if (roezanTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (roezanListActions.includes(action)) {
        return !!(
          model["listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["listID"]]
        );
      } else if (action === "roezan-sendSMS") {
        return !!model["sms-text"];
      }
      break;
    case "klaviyo":
      const klaviyoListActions = [
        "klaviyo-addToList",
        "klaviyo-removeFromList",
      ];
      if (klaviyoListActions.includes(action)) {
        return !!(
          model["listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["listID"]]
        );
      } else if (action === "klaviyo-sendCampaign") {
        return !!(
          model["campaignID"] &&
          formatIntegrationsData(integration_list?.campaigns || {})[
            model["campaignID"]
          ]
        );
      }
      break;
    case "aweber":
      if (action === "aweber-addTag") {
        return !!model["tag-name"];
      } else if (action === "aweber-moveList") {
        return !!(
          model["to-listID"] &&
          formatIntegrationsData(integration_list?.lists || {})[
            model["to-listID"]
          ]
        );
      }
      break;
    case "activecampaign":
      const activecampaignTagActions = [
        "activecampaign-addTag",
        "activecampaign-removeTag",
      ];
      const activecampaignAutomationActions = [
        "activecampaign-addToAutomation",
        "activecampaign-removeFromAutomation",
      ];
      if (activecampaignTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (activecampaignAutomationActions.includes(action)) {
        return !!(
          model["automationID"] &&
          formatIntegrationsData(integration_list?.automations || {})[
            model["automationID"]
          ]
        );
      } else if (action === "activecampaign-moveList") {
        return !!(
          model["toID"] &&
          formatIntegrationsData(integration_list?.lists || {})[model["toID"]]
        );
      }
      break;
    case "slybroadcast":
      const mediaStore = useMediaStore();

      let audioFile = parseInt(integrationActionModel["audiofile"]);
      const mediaFile = mediaStore.audios?.find?.(
        (it) => it?.uniqueID == audioFile
      );

      if (action === "slybroadcast-vmmsg") {
        return !!integrationActionModel["audiofile"] && !!mediaFile;
      }
      break;
    case "wati":
      if (action === "wati-sendWhatsAppTemplate") {
        return !!(
          model["template"] &&
          formatWatiData(integration_list?.templates || {})[model["template"]]
        );
      }
      break;
    case "googlesheets":
      if (action === "googlesheets-addToSheet") {
        return !!(model["spreadsheet"] && model["worksheet"]);
      }
      break;
    case "smartengage":
      const smartengageTagActions = [
        "smartengage-addTag",
        "smartengage-removeTag",
      ];
      const smartengageSequenceActions = [
        "smartengage-addToSequence",
        "smartengage-removeFromSequence",
      ];
      if (smartengageTagActions.includes(action)) {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      } else if (smartengageSequenceActions.includes(action)) {
        return !!model["sequenceID"];
      }
      break;
    case "mailchimp":
      const mailchimpWorkflowActions = [
        "mailchimp-addToWorkflow",
        "mailchimp-removeFromWorkflow",
      ];
      if (mailchimpWorkflowActions.includes(action)) {
        return !!(
          model["workflowID"] &&
          (getMailChimpWorkflowData(integration_list?.workflows || {})[
            model["workflowID"]
          ] ||
            false)
        );
      } else if (action === "mailchimp-addTag") {
        if (!model["tagName"]) return false;
        const tagsInLists = Object.values(integration_list.lists ?? {}).flatMap(
          (list) => Object.values(list.tags ?? {}).map((tag) => tag.name)
        );
        const tagsPendingRegistration = Object.values(
          integration_list.tags ?? {}
        ).map((tag) => tag.name ?? "");
        const tagNames = [...tagsPendingRegistration, ...tagsInLists];
        return tagNames.includes(model["tagName"]);
      } else if (action === "mailchimp-moveList") {
        return !!(
          model["toList"] &&
          (formatIntegrationsData(integration_list?.lists || {})[
            model["toList"]
          ] ||
            false)
        );
      }
      break;
    case "webinar":
      const settingStore = useSettingsStore();
      if (action == "webinar-changesubject") {
        return !!model["subject"];
      } else if (action == "webinar-cta") {
        if (model?.type === "html" && !model?.html) {
          return false;
        }
        if (model?.type === "html") {
          return isHTMLValid(model.html);
        }
        if (model?.type === "iframe") {
          return !!model?.url && URL.canParse(model.url);
        }
        // here we are comparing the end time with video time selected in eveny info
        return !!(model["headline"] && model["subheadline"]);
      } else if (action == "webinar-livechat") {
        return !!model["message"];
      }
      break;
    case "zapier":
      if (action == "zapier-sendWebhook") {
        return !!model["webhook"];
      }
    case "pabbly":
      if (action == "pabbly-sendWebhook") {
        return !!model["webhook"];
      }
    case "campaignrefinery":
      if (action == "campaignrefinery-addGoal") {
        return !!(
          model["goalID"] &&
          formatIntegrationsData(integration_list?.goals || {})[model["goalID"]]
        );
      } else if (action == "campaignrefinery-addTag") {
        return !!(
          model["tagID"] &&
          formatIntegrationsData(integration_list?.tags || {})[model["tagID"]]
        );
      }
    case "mailerlite":
      const groupActions = [
        "mailerlite-addToGroup",
        "mailerlite-removeFromGroup",
      ];
      if (groupActions.includes(action)) {
        return !!(
          model["targetID"] &&
          formatIntegrationsData(integration_list?.groups || {})[
            model["targetID"]
          ]
        );
      }
    case "onesignal":
      if (action == "onesignal-notification") {
        return !!model["template-id"];
      }
      break;
    case "fbaudience":
      const audienceActions = [
        "fbaudience-addToAudience",
        "fbaudience-removeFromAudience",
      ];
      if (audienceActions.includes(action)) {
        const accountAudiences =
          integration_list?.audiences?.[model["adAccountID"]];
        return !!(
          model["audienceID"] &&
          model["adAccountID"] &&
          formatIntegrationsData(accountAudiences || {})[model["audienceID"]]
        );
      } else if (action === "fbaudience-addToFeed") {
        return !!(
          model["pageID"] &&
          model["message"] &&
          formatIntegrationsData(integration_list?.pages || {})[model["pageID"]]
        );
      }
      break;
  }
  return true;
};

export const INTEGRATION_ACTION_DETAILS = {
  onesignal: {
    notification: { Template: "templateName" },
  },
  manychat: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    sendText: { Text: "text" },
    sendMediaMsg: { Text: "text", Type: "type" },
  },
  twilio: {
    textuser: { SMS: "sms-text" },
    sendmms: {
      ["MMS Text"]: "mms-text",
      ["File Name"]: "name",
      ["Media Type"]: "type",
    },
  },
  sendlane: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    moveList: {
      From: "list",
      To: "toListName",
    },
  },
  mailchimp: {
    addTag: { Tag: "tagName" },
    addToWorkflow: { Workflow: "workflowName" },
    removeFromWorkflow: { Workflow: "workflowName" },
    moveList: {
      From: "fromListName",
      To: "toListName",
    },
  },
  infusionsoft: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    subscribeToCampaign: { Campaign: "campaignName" },
    removeFromCampaign: { Campaign: "campaignName" },
  },
  getresponse: {
    addTag: {
      Tag: "tagName",
    },
    moveList: {
      From: "list",
      To: "to-list",
    },
  },
  drip: {
    addTag: { Tag: "tag-name" },
    removeTag: { Tag: "tag-name" },
    addToCampaign: { Campaign: "campaignName" },
    pauseCampaign: { Campaign: "campaignName" },
    removeFromCampaign: { Campaign: "campaignName" },
    startWorkflow: { Workflow: "workflowName" },
    removeFromWorkflow: { Workflow: "workflowName" },
  },
  aweber: {
    moveList: {
      From: "from-list",
      To: "to-list",
    },
    addTag: { Tag: "tag-name" },
  },
  slybroadcast: {
    vmmsg: {
      From: "from",
      Audio: "filename",
    },
  },
  webinar: {
    changesubject: { Subject: "subject" },
    livechat: { Name: "from", Message: "message" },
    encore: { Timeline: "timelineName" },
    cta: { Headline: "headline" },
  },
  activecampaign: {
    addTag: {
      Tag: "tagName",
    },
    removeTag: {
      Tag: "tagName",
    },
    addToAutomation: {
      Automation: "automationName",
    },
    removeFromAutomation: {
      Automation: "automationName",
    },
    moveList: {
      From: "listName",
      To: "toListName",
    },
  },
  pushcrew: {
    notification: {
      "": "title",
    },
    addToSegment: {
      Segment: "segmentName",
    },
    removeFromSegment: {
      Segment: "segmentName",
    },
  },
  convertkit: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    subscribeToCampaign: {
      Sequence: "sequenceName",
    },
  },
  ontraport: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    subscribeToCampaign: {
      Campaign: "campaignName",
    },
    removeFromCampaign: {
      Campaign: "campaignName",
    },
  },
  maropost: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    moveList: {
      From: "from-list",
      To: "to-list",
    },
  },
  kartra: {
    addTag: {
      Tag: "tagID",
    },
    removeTag: {
      Tag: "tagID",
    },
    subscribeToSequence: {
      Sequence: "sequenceName",
    },
    removeFromSequence: {
      Sequence: "sequenceName",
    },
  },
  mailerlite: {
    addToGroup: {
      Group: "targetName",
    },
    removeFromGroup: {
      Group: "targetName",
    },
  },
  fbaudience: {
    addToAudience: {
      Audience: "audienceName",
    },
    removeFromAudience: {
      Audience: "audienceName",
    },
    addToFeed: {
      Page: "pageName",
    },
  },
  smartengage: {
    addToSequence: {
      Sequence: "sequenceName",
    },
    removeFromSequence: {
      Sequence: "sequenceName",
    },
    addTag: {
      Tag: "tagName",
    },
    removeTag: {
      Tag: "tagName",
    },
  },
  campaignrefinery: {
    addGoal: {
      Goal: "goalName",
    },
    addTag: {
      Tag: "tagName",
    },
  },
  dropcowboy: {
    sendsms: { SMS: "sms-text" },
    sendrvm: { Audio: "filename" },
  },
  constantcontact: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
  },
  zapier: {
    sendWebhook: { Webhook: "webhook" },
  },
  pabbly: {
    sendWebhook: { Webhook: "webhook" },
  },
  wati: {
    sendWhatsAppTemplate: { Template: "template" },
  },
  icontact: {
    moveToList: { List: "listName" },
  },
  klaviyo: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    sendCampaign: { Campaign: "campaignName" },
  },
  roezan: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    sendSMS: { SMS: "sms-text" },
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
  },
  slicktext: {
    sendsms: { SMS: "sms-text" },
    sendmms: { MMS: "mms-text", Type: "type" },
  },
  manychatwhatsapp: {
    sendWhatsappTemplate: { Automation: "flowName" },
  },
  googleSheets: {
    addToSheet: { Spreadsheet: "spreadsheet", Worksheet: "worksheet" },
  },
  iterable: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    sendCampaign: { Campaign: "campaignName" },
  },
  hubspot: {
    addToList: { List: "listName" },
    removeFromList: { List: "listName" },
    addToWorkflow: { Workflow: "workflowName" },
    removeFromWorkflow: { Workflow: "workflowName" },
  },
  mailgun: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  mailjet: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  gmail: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  postmark: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  sendgrid: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  sendinblue: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  smtp: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  sparkpost: {
    sendMail: {
      Email: "emailFrom",
      Name: "nameFrom",
      Subject: "emailSubject",
    },
  },
  campaignmonitor: {
    removeSubscriber: { Client: "client", ["List Name"]: "list" },
  },
  gohighlevel: {
    addTag: { Tag: "tagName" },
    removeTag: { Tag: "tagName" },
    addToWorkflow: { Workflow: "workflowName" },
    removeFromCampaign: { Campaign: "campaignName" },
    subscribeToCampaign: { Campaign: "campaignName" },
  },
  slack: {
    supportChat: {},
  },
  webhook: {
    webhook: {
      Name: "name",
      Address: "address",
    },
  },
};
