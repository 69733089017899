<template>
  <div>
    <div v-if="!isuploadMediaModalMinimize">
      <div
        class="fixed inset-0 w-full h-full bg-black/60 backdrop-blur-sm z-[999999]"
      ></div>
      <div
        class="flex flex-col w-full gap-3 max-w-[550px] fixed inset-0 m-auto h-fit z-[9999999]"
      >
        <div
          class="p-8 flex flex-col gap-5 bg-white dark:bg-neutral-900 rounded-2xl w-full"
        >
          <div class="flex items-center w-full justify-between">
            <h1
              class="font-semibold text-xl -tracking-[0.1%] text-neutral-800 dark:text-neutral-200"
            >
              Upload Your Media
            </h1>
            <div class="flex gap-2 items-center">
              <svg
                v-if="queueList.length > 0"
                dusk="minimizeMedia"
                class="cursor-pointer text-neutral-800 dark:text-neutral-200 w-6 h-6"
                @click="handleMinimize"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 12H6"
                />
              </svg>
              <svg
                class="cursor-pointer text-neutral-800 dark:text-neutral-200"
                id="uploadMedia-closeIcon"
                @click="[handleMediaUpload(), handleReset()]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6L18 18"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            v-if="
              route?.fullPath?.includes('/media') ||
              route?.fullPath?.includes('/create')
            "
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
            class="flex flex-col gap-3 justify-center items-center py-[50px] px-8 w-full bg-[#FAFDFF] dark:bg-neutral-800 rounded-xl border-2 border-dashed border-blue-600 dark:border-blue-400"
          >
            <img
              class="w-fit"
              :src="`${cdnURL}upload_media.svg`"
              alt="main img"
            />
            <div class="flex items-center gap-0.5">
              <h1
                class="font-bold text-base leading-none text-neutral-800 dark:text-neutral-200"
              >
                Drag and drop files, or
              </h1>
              <div class="relative">
                <input
                  class="custom-file-input absolute top-0 w-full h-full opacity-0 cursor-pointer"
                  type="file"
                  multiple
                  @change="handleSelect"
                  accept=".jpg, .jpeg, .png, .gif, .tiff, .mp3, .mp4, .wav, .m4a, .ico, .hevc, .JPG, .JPEG, .PNG, .GIF, .TIFF, .MP3, .MP4, .WAV, .M4A, .ICO, .HEVC"
                  id="browseFiles-button"
                />
                <p
                  class="font-bold text-base leading-none text-blue-600 dark:text-blue-400"
                >
                  Browse
                </p>
              </div>
            </div>
            <p
              class="font-normal text-sm text-gray-600 dark:text-neutral-400 text-center"
            >
              Support {{ state.allowedTypes.join(", ") }} file formats
            </p>
          </div>
          <div
            v-if="queueList.length > 0"
            class="px-5 py-4 flex flex-col gap-4 w-full border box-border border-neutral-200 dark:border-neutral-700 rounded-lg overflow-y-auto h-full max-h-[345px] content-scroll"
          >
            <div class="flex flex-col gap-3">
              <div
                class="flex flex-row gap-4 items-center w-full h-full"
                v-for="(item, index) in queueList"
                :key="index"
              >
                <div class="w-full min-w-[80px] max-w-[80px]">
                  <img
                    v-if="item.type.match(/audio.*/)"
                    :src="`${cdnURL}new_audio_icon.svg`"
                    alt="uploaded_images"
                    class="w-full h-[45px] rounded object-cover"
                    :class="!getStatus(item).uploaded ? '!opacity-50' : ''"
                  />
                  <img
                    v-else-if="item.thumbnail"
                    :src="item.thumbnail"
                    alt="uploaded_images"
                    class="w-full h-[45px] rounded object-cover"
                    :class="!getStatus(item).uploaded ? '!opacity-50' : ''"
                  />
                  <div
                    v-else
                    class="w-full h-[45px] animate-pulse bg-neutral-300 rounded object-cover"
                  ></div>
                </div>
                <div class="flex items-center gap-6 w-full">
                  <div class="w-full flex flex-col gap-2">
                    <div class="flex justify-between flex-col">
                      <p
                        class="font-medium text-base text-neutral-800 dark:text-neutral-200 truncate w-full max-w-[300px]"
                        :title="item.name"
                      >
                        {{ getStatus(item).fileType }}
                        {{ item.name }}
                      </p>
                      <div
                        v-if="!isHandleUploaded(item)"
                        class="w-full flex items-end gap-2 min-w-[150px] truncate"
                      >
                        <p
                          class="font-normal text-xs text-neutral-500 dark:text-neutral-400"
                        >
                          {{ formatFileSize(item.size) }}
                        </p>
                        <div
                          class="flex items-center gap-1 font-normal text-xs text-neutral-500 dark:text-neutral-400 w-fit"
                        >
                          <p>
                            {{
                              formatFileSize(
                                getStatus(item).speed || new Number(0)
                              )
                            }}/s
                          </p>
                          <p>-</p>
                          <p>
                            {{
                              isNaN(currentPosition(getStatus(item).remaining))
                                ? "Pending"
                                : formatSeconds(getStatus(item).remaining)
                            }}
                          </p>
                        </div>
                      </div>
                      <div v-else class="text-green-600 text-sm">Completed</div>
                    </div>

                    <div class="h-1 bg-blue-50 rounded-md w-full">
                      <div
                        :style="fillerStyles(getStatus(item).progress)"
                      ></div>
                    </div>
                  </div>

                  <div>
                    <div
                      v-if="!getStatus(item).uploaded"
                      class="p-0.5 bg-neutral-50 rounded-full dark:bg-neutral-700 cursor-pointer"
                    >
                      <svg
                        @click="handleCancelUpload(item.uuid)"
                        width="16"
                        height="17"
                        class="text-neutral-800 dark:text-neutral-200"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2790_11272)">
                          <path
                            d="M7.99999 7.55781L11.3 4.25781L12.2427 5.20048L8.94266 8.50048L12.2427 11.8005L11.3 12.7431L7.99999 9.44315L4.69999 12.7431L3.75732 11.8005L7.05732 8.50048L3.75732 5.20048L4.69999 4.25781L7.99999 7.55781Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2790_11272">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <svg
                      v-else-if="isHandleUploaded(item)"
                      @click="
                        (showDeleteModal = true),
                          (deleteInfo.type =
                            getStatus(item).fileType?.split('/')[0]),
                          (deleteInfo.uuid = getStatus(item).fileID),
                          (fileUUID = item.uuid)
                      "
                      width="16"
                      class="cursor-pointer"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.82692 6.5L9.59615 12.5M6.40385 12.5L6.17308 6.5M12.8184 4.36038C13.0464 4.39481 13.2736 4.43165 13.5 4.47086M12.8184 4.36038L12.1065 13.615C12.0464 14.3965 11.3948 15 10.611 15H5.38905C4.60524 15 3.95358 14.3965 3.89346 13.615L3.18157 4.36038M12.8184 4.36038C12.0542 4.24496 11.281 4.15657 10.5 4.09622M2.5 4.47086C2.72638 4.43165 2.95358 4.39481 3.18157 4.36038M3.18157 4.36038C3.94585 4.24496 4.719 4.15657 5.5 4.09622M10.5 4.09622V3.48546C10.5 2.69922 9.8929 2.04282 9.10706 2.01768C8.73948 2.00592 8.37043 2 8 2C7.62957 2 7.26052 2.00592 6.89294 2.01768C6.1071 2.04282 5.5 2.69922 5.5 3.48546V4.09622M10.5 4.09622C9.67504 4.03247 8.84131 4 8 4C7.15869 4 6.32496 4.03247 5.5 4.09622"
                        stroke="#E11D48"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="queList.length > 0">
            <ReusableUIButtonsButtonPrimary
              size="medium"
              :disabled="!checkAllUploaded"
              :text="checkAllUploaded ? 'Done' : 'Uploading...'"
              width="w-full"
              class="w-full"
              @click="[handleMediaUpload(), handleReset()]"
            />
          </div>
          <ReusableUIModalsDeleteMedia
            v-if="showDeleteModal === true"
            :title="deleteInfo.type"
            @showDeleteModal="
              (val, id) => [
                (showDeleteModal = !showDeleteModal),
                handleDelete(id),
              ]
            "
            :deleteID="deleteInfo.uuid"
            :getBackID="fileUUID"
            :mediaType="deleteInfo.type"
          />
        </div>

        <ReusableUIToaster
          :showToast="showToast"
          :label="toastMessage"
          :message="toastLabel"
          :status="toastStatus"
        />
      </div>
    </div>
    <!-- v-else -->
    <div
      v-else="isuploadMediaModalMinimize && !checkAllUploaded"
      @click="handleMinimize"
      class="fixed right-16 cursor-pointer z-10 w-full max-w-[300px] px-3 py-3 bg-[#d1d0f5] flex items-center justify-between"
      :class="route.fullPath?.includes('/create') ? 'top-28' : 'bottom-6'"
    >
      <div class="h-1 bg-blue-100 rounded-full left-0 absolute bottom-0 w-full">
        <!-- <div class="w-full max-w-[112px] lg:max-w-[256px] h-1 bg-blue-600 rounded-md"></div> -->
        <div
          class="max-w-[100%]"
          :style="
            fillerStyles(
              (lengthOfUploadedItems / Object.keys(queueList)?.length) * 100,
              true
            )
          "
        ></div>
      </div>
      <div class="w-full flex relative">
        <div
          v-if="lengthOfUploadedItems != Object.keys(queueList)?.length"
          class="w-12 -top-4 -left-2 absolute h-12"
        >
          <img src="/icons/animation_upload.gif" />
        </div>
        <div class="pr-2" v-else>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM15.6103 10.1859C15.8511 9.84887 15.773 9.38046 15.4359 9.1397C15.0989 8.89894 14.6305 8.97701 14.3897 9.31407L11.1543 13.8436L9.53033 12.2197C9.23744 11.9268 8.76256 11.9268 8.46967 12.2197C8.17678 12.5126 8.17678 12.9874 8.46967 13.2803L10.7197 15.5303C10.8756 15.6862 11.0921 15.7656 11.3119 15.7474C11.5316 15.7293 11.7322 15.6153 11.8603 15.4359L15.6103 10.1859Z"
              fill="#047857"
            />
          </svg>
        </div>

        <p
          class="text-sm text-neutral-700 font-semibold items-center gap-2 flex"
          :class="
            lengthOfUploadedItems != Object.keys(queueList)?.length
              ? 'ml-10'
              : 'text-green-700'
          "
        >
          {{ lengthOfUploadedItems + "/" + Object.keys(queueList)?.length }}
          {{ " " }} Items Uploaded
        </p>
      </div>
      <div class="w-5 h-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          @click="closeUploadProgress()"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="{1.5}"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUploadStore } from "~/store/upload";
import { useCommonStore } from "~~/store/common";
import { useAuthStore } from "~~/store/auth";
import { useMediaStore } from "~~/store/media";
// CDN set up
const config = useRuntimeConfig();
const commonStore = useCommonStore();
const cdnURL = config.public.cdnURL;
const uploadStore = useUploadStore();
const authStore = useAuthStore();
const mediaStore = useMediaStore();
const {
  queueList,
  queueStatusList,
  checkAllUploaded,
  getMediaType,
  getUploadedMedia,
} = storeToRefs(uploadStore);
const { isuploadMediaModalMinimize } = storeToRefs(commonStore);
const { uploadToFolder } = storeToRefs(mediaStore);
const queList = ref(queueList || []);
const showStatus = ref(true);
const showToast = ref(false);
const toastMessage = ref("");
const toastLabel = ref("");
const toastStatus = ref("info");
const mediaItem = ref(false);
const fileUUID = ref("");
const showDeleteModal = ref(false);
const miniMize = ref(false);
const lengthOfUploadedItems = ref(0);
const route = useRoute();
const deleteInfo = ref({
  uuid: null,
  type: "image",
});
const filesLength = ref();
const filesRemaining = ref(0);
const handleReset = () => {
  uploadStore.clear();
  uploadStore.allUploaded = false;
  for (const key in queueStatusList.value) {
    if (queueStatusList.value.hasOwnProperty(key)) {
      delete queueStatusList.value[key];
    }
  }
};
const closeUploadProgress = () => {
  commonStore.setuploadMediaModal(false);
  uploadStore.clear();
  uploadStore.allUploaded = false;
  for (const key in queueStatusList.value) {
    if (queueStatusList.value.hasOwnProperty(key)) {
      delete queueStatusList.value[key];
    }
  }
};
const mphToSecondsPerMile = (speedInMPH) => {
  if (speedInMPH <= 0) {
    return "Invalid input. Speed must be greater than zero.";
  }
  const secondsPerMile = 3600 / speedInMPH;

  return secondsPerMile;
};

watch(
  [
    () => queList.value,
    () => filesLength.value,
    () => queueStatusList.value?.progress,
  ],
  () => {
    queList.value = queueList.value;
    filesLength.value = queueList.value.length;
  },
  { immediate: true, deep: true }
);

const getStatus = (item) => {
  const status = queueStatusList.value[item.uuid];
  if (!status) {
    return {};
  }

  return status;
};

const fillerStyles = (percentage, mini) => {
  let backgroundColor = "";
  if (percentage) {
    backgroundColor = percentage >= 100 && mini ? "#15803d" : "#2563eb";
  }
  return {
    height: "100%",
    width: `${percentage}%`,
    maxWidth: "100%",
    backgroundColor: backgroundColor,
    transition: "width 1.4s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };
};
const formatFileSize = (byte) => {
  // Format file size implementation goes here
  if (byte < 1024) {
    return `${byte}B`;
  }
  if (byte < 1024 * 1024) {
    return `${Math.floor(byte / 1024)}KB`;
  }
  if (byte < 1024 * 1024 * 1024) {
    return `${Math.floor(byte / 1024 / 1024)}MB`;
  }
  return `${(byte / 1024 / 1024 / 1024).toFixed(2)}GB`;
};

const formatFileSpeed = (start, byte) => {
  // Format file size implementation goes here
  if (byte < 1024) {
    return `${start - byte}B/s`;
  }
  if (byte < 1024 * 1024) {
    return `${Math.floor((start - byte) / 1024)}KB/s`;
  }
  if (byte < 1024 * 1024 * 1024) {
    return `${Math.floor((start - byte) / 1024 / 1024)}MB/s`;
  }
  return `${((start - byte) / 1024 / 1024 / 1024).toFixed(2)}GB/s`;
};
const currentPosition = (position) => {
  return Math.floor(position / 100) * 100;
};

const state = reactive({
  allowedTypes: [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".tiff",
    ".mp3",
    ".mp4",
    ".wav",
    ".m4a",
    ".ico",
    ".hevc",
  ],
});

const handleSelect = (e) => {
  handleFiles(e.target.files);
};
const handleFiles = (files) => {
  for (const file of files) {
    const ext = "." + file.name.split(".").pop();
    if (!state.allowedTypes.includes(ext.toLowerCase())) {
      // this.error = "This file type is not supported.";
      continue;
    }
    file.uuid = uuidv4(); // uuidv4 implementation goes here
    file.isReady = true;
    file.folderID = uploadToFolder.value;
    const index = queList.value.length;
    const fileURL = URL.createObjectURL(file);

    if (uploadStore.isUploadable(file.size)) {
      if (file.type.match(/image.*/)) {
        file.isReady = false;
        file.thumbnail = fileURL;
        file.isUploaded = false;
        file.isReady = true;

        // Set queue
        // uploadStore.queue
        uploadStore.$state.queue[index] = file;
      } else if (file.type.match(/video.*/) || file.type.match(/octet/)) {
        file.isReady = false;
        const thumbGetter = document.createElement("video");
        const fileURL = URL.createObjectURL(file);
        thumbGetter.src = fileURL;
        thumbGetter.currentTime = 1;
        // FIXME: Temporary
        // file.isReady = true;
        // Vue.set(this.queue, index, file);
        file.isReady = true;
        uploadStore.$state.queue[index] = file;
        // Vue.set(this.queue, index, file);
        thumbGetter.addEventListener(
          "canplaythrough",
          () => {
            const canvas = document.createElement("canvas");
            canvas.width = thumbGetter.videoWidth;
            canvas.height = thumbGetter.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(thumbGetter, 0, 0, canvas.width, canvas.height);

            file.thumbnail = canvas.toDataURL();
          },
          false
        );
      }
      uploadStore.$state.queue[index] = file;
    } else {
      handleToaster("error");
    }
  }
  uploadStore.uploadNext();
};
const handleToaster = (status, item) => {
  showToast.value = true;
  document.body.style.overflow = "hidden";
  if (status == "error") {
    toastMessage.value = "Media upload failed";
    toastLabel.value = "There was an error with the media upload";
  } else if (status == "success") {
    toastMessage.value = "Media upload successful";
    toastLabel.value = "The media was uploaded successfuly";
  }
  toastStatus.value = status;
};
const filelist = ref([]);

const remove = (index) => {
  filelist.value.splice(index, 1);
};
const dragover = (event) => {
  event.preventDefault();
  if (!event.currentTarget.classList.contains("bg-green-50")) {
    event.currentTarget.classList.remove("bg-neutral-50");
    event.currentTarget.classList.add("bg-neutral-100");
  }
};
const dragleave = (event) => {
  // Clean up
  event.currentTarget.classList.add("bg-neutral-50");
};
const drop = (event) => {
  event.preventDefault();
  handleFiles(event.dataTransfer.files);
  event.currentTarget.classList.add("bg-gray-100");
  event.currentTarget.classList.remove("bg-green-300");
};
const formatSeconds = (total) => {
  const hour = Math.floor(total / 3600);
  const minute = Math.floor((total % 3600) / 60);
  let second = Math.floor((total % 3600) % 60);
  if (minute > 0 || hour > 0) {
    return `${hour > 0 ? `${hour} Hr${hour > 1 ? "s" : ""} ` : ""} ${
      minute > 0 ? `${minute} Min${minute > 1 ? "s" : ""} ` : ""
    }`;
  }
  if (second < 0) {
    return "Processing";
  } else {
    return `${second}s left`;
  }
};

const handleCancelUpload = (id) => {
  uploadStore.deleteFile(id);
};

const handleDelete = (id) => {
  uploadStore.deleteFile(id);
};

const isHandleUploaded = (item) => {
  const status = queueStatusList.value[item.uuid]?.uploaded;
  filesRemaining.value = queList.length;
  return status;
};
const handleMediaUpload = () => {
  commonStore.setuploadMediaModal(false);
};
const handleMinimize = () => {
  commonStore.setuploadMediaModalMinimize(!isuploadMediaModalMinimize.value);
  mediaStore.setUploadMediaToFolder("");
};

watch(
  () => getUploadedMedia.value,
  (progressArray) => {
    const temp = Object.values(queueList.value);
    lengthOfUploadedItems.value = temp.length - getUploadedMedia.value.length;
  },
  { deep: true, immediate: true, flush: "sync" }
);

onUnmounted(() => {
  mediaStore.fetchVideos();
  mediaStore.fetchAudios();
  mediaStore.fetchImages();
  mediaStore.getFolders();
});
</script>
