import { defineStore } from "pinia";
import { useTimelineStore } from "./timeline";
import { useIntegrationStore } from "./integration";
export const useSettingsStore = defineStore({
  id: "settings-store",
  state: () => {
    return {
      autoresponder: {},
      registration: {},
      page: {},
      general: {},
      languages: {},
      integration: {},
      customFieldTypes: {},
      customFieldTemplates: {},
      personalizeFields: {},
    };
  },
  actions: {
    async fetchGlobalSettings({ type }) {
      const response = await call(`globalsetting/${type}`, "POST");
      if (response) {
        const { success } = response;
        if (type == "language") {
          this.languages = success;
        } else if (type == "customfieldtype") {
          this.customFieldTypes = success;
        } else if (type == "customfieldtemplate") {
          this.customFieldTemplates = response;
        } else if (type == "personalize") {
          this.personalizeFields = success;
        } else if (type == "tooltip") {
        }

        return success;
      }
      return false;
    },
    async fetchSettings(webinarTimeline, setting) {
      const payload = {
        webinarTimeline: webinarTimeline,
        nodeID: setting,
      };
      const response = await call("/generalsetting/get", "POST", payload);
      if (response.success) {
        const { success } = response;

        const integrationStore = useIntegrationStore();
        let sModel = Object.assign({}, success);

        if (setting == "general") {
          sModel = {
            visible: success.visible,
            userGroup: success.userGroup || [],
            enabled: success.enabled || false,
            replayStart: success.replayStart || "15",
            joinStart: success.joinStart || "15",
            deliveryPlatform: success.deliveryPlatform || "default",
            integrationID:
              success.deliveryPlatform == "zoom"
                ? Object.values(integrationStore.integrationsList).filter(
                    (integration) => integration.type == "ZOOM"
                  )[0]?.integrationid
                : null,
            webinarType: success.webinarType || "automatic",
            webinarTemplate: success.webinarTemplate || {},
            meetingTemplate: success.meetingTemplate || {},
            timelineName: success.timelineName || "",
            description: success.description || "",
            organizer: success.organizer || [],
            presenters: success.presenters || [],
            videoFile: success.videoFile || "",
            scheduleLength:
              success.hasOwnProperty("scheduleLength") &&
              success.scheduleLength >= 0
                ? success.scheduleLength
                : 1,
            startAfter: success.startAfter || 0,
            webinarVideoLength: success.webinarVideoLength || "",
            timeStamp: success.timeStamp ? success.timeStamp : null,
            eventType: success.eventType ? success.eventType : null,
            templateType: success.templateType ? success.templateType : null,
            manualLength: success.manualLength || 0,
            language: success.language || "en",
            multiEventCustomFields: success.multiEventCustomFields || false,
            joinDomain: success.joinDomain || "",
            manualControl: success.manualControl
              ? {
                  enabled: success.manualControl.enabled || false,
                  duration: success.manualControl.duration || "",
                }
              : { enabled: false },
            advanced: success.advanced
              ? {
                  enabled: success.advanced.enabled || false,
                  appendIntro: success.advanced.appendIntro || null,
                  appendOutro: success.advanced.appendOutro || null,
                }
              : { enabled: false },
            countdown: success.countdown
              ? {
                  enabled: success.countdown.enabled || false,
                  duration: success.countdown.duration || 0,
                  manualLength: success.countdown.manualLength || 0,
                  image: success.countdown.image || "",
                }
              : { enabled: false, manualLength: 0 },
            replay: success.replay
              ? {
                  enabled: success.replay.enabled || false,
                  replayDays: success.replay.replayDays || 0,
                  replayHours: success.replay.replayHours || 0,
                  replayMinutes: success.replay.replayMinutes || 0,
                  expireOnMid: success.replay.expireOnMid,
                }
              : { enabled: false, expireOnMid: 1 },
            hls: {
              audio: (success.hls && success.hls.audio) || {
                source: "main", // secondary
              },
              audienceCount: (success.hls && success.hls.audienceCount) || {
                enabled: true,
              },
              liveStream: (success.hls && success.hls.liveStream) || {
                enabled: true,
              },
              pre: (success.hls && success.hls.pre) || {
                background: {
                  type: "default", // custom
                  image: "",
                },
              },
              logo: (success.hls && success.hls.logo) || {
                type: "default", // custom
                image: "",
              },
              favicon: (success.hls && success.hls.favicon) || {
                type: "default",
                image: "",
              },
              attendance: (success.hls && success.hls.attendance) || {
                type: "default", // simulated, peak
                simulations: {
                  0: {
                    label: "Start",
                    count: 15,
                  },
                  25: {
                    label: "25%",
                    count: 30,
                  },
                  50: {
                    label: "50%",
                    count: 20,
                  },
                  100: {
                    label: "End",
                    count: 10,
                  },
                },
                peak: {
                  startPoint: 10,
                  surgePoint: 23,
                  peak: 40,
                  end: 18,
                  surgePointTime: 2, // First X minutes surge
                  peakTime: 15, // Peak attendance at 15 minutes
                },
              },
              livechat: (success.hls && success.hls.livechat) || {
                enabled: true,
                type: "all", // support
                isTimestamp: true,
              },
              code: (success.hls && success.hls.code) || "",
              waiting_code: (success.hls && success.hls.waiting_code) || "",
              css: (success.hls && success.hls.css) || "",
              canned: (success.hls && success.hls.canned) || [],
              autoChat: (success.hls && success.hls.autoChat) || [],
              pip: (success.hls && success.hls.pip) || {
                enabled: true,
                seconds: 0,
              },
            },
            timeCounter: success.timeCounter
              ? {
                  enabled: success.timeCounter.enabled,
                  direction: success.timeCounter.direction || "up",
                }
              : {
                  enabled: true,
                  direction: "up",
                },
            secondaryVideo: success.secondaryVideo
              ? {
                  enabled: success.secondaryVideo.enabled || false,
                  video: success.secondaryVideo.video || null,
                }
              : { enable: false, video: null },
            splitVideo: success.splitVideo
              ? {
                  enabled: success.splitVideo.enabled || false,
                  videos: success.splitVideo.videos || [],
                }
              : { enabled: false, videos: [] },
            multiEvents: success.multiEvents
              ? success.multiEvents
              : {
                  settings: {},
                  nodes: {},
                },
            highDefinition: success.highDefinition
              ? {
                  enabled: !!success.highDefinition.enabled,
                }
              : { enabled: false },
            domain: success.domain,
            muteAll: success.muteAll || false,
            autoRecording: success.autoRecording || false,
            participantVideo: success.participantVideo || false,
          };
          this.general = sModel;
        } else if (setting == "registration") {
          sModel = {
            customForms: success.customForms
              ? success.customForms.constructor === Array
                ? success.customForms || []
                : []
              : [],
            block: success.block || 0,
            replayStart: success.replayStart || "15",
            joinStart: success.joinStart || "15",
            registrationStart:
              success.registrationStart || success.replayStart || "15",
            registrationSubject: success.registrationSubject || "",
            registrationBody: success.registrationBody || "",
            customeEnable: success.customeEnable || false,
            type: success.type || "one-time",
            isInnerPageEnabled: success.isInnerPageEnabled || false,
            whitelist: success.whitelist || {
              enabled: false,
              domains: "",
            },
            setupType: success?.setupType || "page",
          };
          this.registration = sModel;
        } else if (setting == "page") {
          sModel = {
            confirmation: success.confirmation || {
              url: "",
              countdown: defaultCountdown.countdown,
            },
            registration: success.registration
              ? success.registration
              : defaultRegCountdown,
            countdown: success.countdown
              ? Object.keys(success.countdown).length == 0 ||
                !success.countdown["media"]
                ? defaultCount
                : success.countdown
              : defaultCount,
            replay: success.replay ? success.replay : defaultCountdown,
            pixel: success.pixel ? success.pixel : defaultCountdown,
            cta: success.cta ? (success.cta.url ? success.cta : {}) : {},
            join: success.join ? success.join : {},
            builder: success.builder ? success.builder : {},
          };
          this.page = sModel;
        } else if (setting == "integration") {
          sModel = Object.values(sModel);
          this.integration = sModel;
        } else if (setting == "autoresponder") {
          sModel = {
            integrations: success.integrations || {},
            webinarEnabled: success.webinarEnabled == "true" ? "true" : "false",
            confirmationEnabled:
              success.confirmationEnabled == "true" ? "true" : "false",
            reminderEnabled:
              success.reminderEnabled == "true" ? "true" : "false",
            nonattendeeEnabled:
              success.nonattendeeEnabled == "true" ? "true" : "false",
            attendeeEnabled:
              success.attendeeEnabled == "true" ? "true" : "false",
            multiEvents: success.multiEvents ? success.multiEvents : {},
          };
          sModel = formatAutoresponder(success, sModel);
          if (Object.keys(sModel.multiEvents).length == 0)
            sModel.multiEvents = {};
          Object.keys(sModel.integrations).map((key) => {
            if (Object.keys(sModel.integrations[key]).length != 1) {
              if (sModel.integrations[key].customFields !== undefined) {
                if (sModel.integrations[key].customFields.constructor === Array)
                  sModel.integrations[key].customFields = {};
              }
            }
          });
          this.autoresponder = sModel;
        }
        // this.leads = success;
        return sModel;
      }
      return false;
    },
    async loadSettings(webinarTimeline) {
      await Promise.allSettled([
        // For general
        this.fetchSettings(webinarTimeline, "general"),
        // For integration
        this.fetchSettings(webinarTimeline, "integration"),
        // For autoresponder
        this.fetchSettings(webinarTimeline, "autoresponder"),
        // For registration
        this.fetchSettings(webinarTimeline, "registration"),
        // For page
        this.fetchSettings(webinarTimeline, "page"),
      ]);
    },
    // Get Dynamic Page Editor ( with Custom Fields, page : registration => 0, confirmation => 1, replay => 2 )
    getDynamicList({ page, isRegistrantTimezone }) {
      let withCustomField = [1, 2, 3]; // Page with Custom Field
      let prefix = "{{!";
      if (isRegistrantTimezone) {
        prefix = "{{!reg-";
      }
      let initialList = [
        // Registration
        [
          "{{!webinar-subject}}",
          "{{!webinar-body}}",
          prefix + "dayofweek}}",
          prefix + "month}}",
          prefix + "dayofmonth}}",
          prefix + "year}}",
          prefix + "timeZone}}",
          "{{!timeEST}}",
          "{{!timePST}}",
          "{{!timeCST}}",
          "{{!timeMST}}",
          "{{!timeGMT}}",
          "{{!timeAEST}}",
          "{{!timeBST}}",
          "{{!timeCET}}",
          "{{!timeMSK}}",
          "{{!timeJST}}",
          "{{!timeIST}}",
          "{{!timeUSER}}",
          "{{!webinarTimestamp}}",
        ],
        // Confirmation
        [
          "{{!webinar-subject}}",
          "{{!webinar-body}}",
          prefix + "ReplayExpiredayofweek}}",
          prefix + "ReplayExpiremonth}}",
          prefix + "ReplayExpiredayofmonth}}",
          prefix + "ReplayExpireyear}}",
          prefix + "ReplayExpiretimeZone}}",
          "{{!ReplayExpiretimeEST}}",
          "{{!ReplayExpiretimePST}}",
          "{{!ReplayExpiretimeCST}}",
          "{{!ReplayExpiretimeMST}}",
          "{{!ReplayExpiretimeGMT}}",
          "{{!ReplayExpiretimeAEST}}",
          "{{!ReplayExpiretimeUSER}}",
          "{{!firstname}}",
          "{{!lastname}}",
          "{{!email}}",
          "{{!webinarTimestamp}}",
          "{{!replayTimestamp}}",
        ],
        // Replay
        [
          "{{!webinar-subject}}",
          "{{!webinar-body}}",
          "{{!add_to_calendar_google}}",
          "{{!add_to_calendar_apple}}",
          prefix + "dayofweek}}",
          prefix + "month}}",
          prefix + "dayofmonth}}",
          prefix + "year}}",
          prefix + "timeZone}}",
          "{{!timeEST}}",
          "{{!timeMST}}",
          "{{!timePST}}",
          "{{!timeCST}}",
          "{{!timeGMT}}",
          "{{!timeAEST}}",
          "{{!timeBST}}",
          "{{!timeCET}}",
          "{{!timeMSK}}",
          "{{!timeJST}}",
          "{{!timeIST}}",
          "{{!timeUSER}}",
          "{{!firstname}}",
          "{{!lastname}}",
          "{{!email}}",
          "{{!joinurl}}",
          "{{!platformurl}}",
          "{{!registrantID}}",
          "{{!webinar-id}}",
          "{{!webinar-wtl}}",
          "{{!phone}}",
          "{{!webinarTimestamp}}",
          "{{!replayTimestamp}}",
        ],

        [
          "{{!webinar-subject}}",
          "{{!webinar-body}}",
          prefix + "dayofweek}}",
          prefix + "month}}",
          prefix + "dayofmonth}}",
          prefix + "year}}",
          prefix + "timeZone}}",
          "{{!timeEST}}",
          "{{!timeMST}}",
          "{{!timePST}}",
          "{{!timeCST}}",
          "{{!timeGMT}}",
          "{{!timeAEST}}",
          "{{!timeBST}}",
          "{{!timeCET}}",
          "{{!timeMSK}}",
          "{{!timeJST}}",
          "{{!timeIST}}",
          "{{!timeUSER}}",
          "{{!firstname}}",
          "{{!lastname}}",
          "{{!email}}",
          "{{!joinurl}}",
          "{{!platformurl}}",
          prefix + "ReplayExpiredayofweek}}",
          prefix + "ReplayExpiremonth}}",
          prefix + "ReplayExpiredayofmonth}}",
          prefix + "ReplayExpireyear}}",
          prefix + "ReplayExpiretimeZone}}",
          "{{!ReplayExpiretimeEST}}",
          "ReplayExpiretimeMST",
          "{{!ReplayExpiretimePST}}",
          "{{!ReplayExpiretimeCST}}",
          "{{!ReplayExpiretimeGMT}}",
          "{{!ReplayExpiretimeAEST}}",
          "{{!ReplayExpiretimeUSER}}",
          "{{!webinarTimestamp}}",
          "{{!replayTimestamp}}",
        ],
      ];
      var sList = initialList[page];
      if (withCustomField.includes(page)) {
        // Need Custom Field
        let customForms = this.registration.customForms
          ? this.registration.customForms
          : [];
        customForms.map((field) => {
          sList.push("{{!subscriber-" + field.name + "}}");
        });
      }
      return sList;
    },
    async setTimelinesMaster(id) {
      const timelineStore = useTimelineStore();
      let temp = timelineStore.timelinesList[id];
      temp.setting.autoresponder = this.autoresponder;
      temp.setting.registration = this.registration;
      temp.setting.page = this.page;
      temp.setting.general = this.general;
      temp.setting.integration = this.integration;
      const data3 = new FormData();
      data3.append("webinarTimeline", id);
      data3.append("blocks", JSON.stringify(temp));
      data3.append("isPast", false);

      const response = await call("/webinartimelinemaster/set", "POST", data3, true);
      if (response && response.error?.response?.data?.message) {
        return { error: response.error.response.data.message };
      }
      if (response) {
        const { success } = response;
        // this.timelines = success;
      }
      return response;
    },
    setSettings({ setting, model }) {
      const timelineStore = useTimelineStore();
      switch (setting) {
        case "general":
          this.general = model;

          break;
        case "registration":
          this.registration = model;
          break;
        case "page":
          this.page = model;
          break;
        case "integration":
          this.integration = model;
          break;
        case "autoresponder":
          this.autoresponder = model;
          break;
        case "events":
          timelineStore.timelineNodes = model;
        default:
          break;
      }
    },

    async addNewAction(
      node,
      timelineID,
      onEdit,
      duplicate,
      key,
      oldID,
      cancel
    ) {
      let eventNodes = { ...this.general.multiEvents.nodes };

      if (Object.keys(eventNodes).length === 0) {
        eventNodes = {};
      }

      if (!onEdit) {
        eventNodes = removeNodeByID(eventNodes, node.id);
      }

      if (onEdit) {
        if (!eventNodes[key]) {
          eventNodes[key] = [];
        }
        eventNodes = removeNodeByID(eventNodes, oldID);
        let newNode = JSON.parse(JSON.stringify(node));
        eventNodes[key].push(newNode);
      }

      if (!eventNodes[key]) {
        eventNodes[key] = [];
      }

      if ((node && !onEdit) || duplicate) {
        if (duplicate) {
          eventNodes[key].push(node);
        } else {
          eventNodes[key].push(node);
        }
      }
      eventNodes = Object.keys(eventNodes)
        .filter((key) => eventNodes[key].length > 0)
        .reduce((obj, key) => {
          obj[key] = eventNodes[key];
          return obj;
        }, {});

      this.general.multiEvents = {
        ...this.general.multiEvents,
        nodes: eventNodes,
      };

      if (cancel) return true;
      // Set settings for the added event
      this.refreshEventSetting();
      return await this.setTimelinesMaster(timelineID);
    },
    refreshEventSetting() {
      // Setting
      let eventSettings = this.general.multiEvents.settings;
      let newEventSettings = {};
      let allEventNodes = this.allEvents.map((event) => {
        return event.node;
      });
      allEventNodes.map((eventNode) => {
        let uuid = eventNode.id;
        if (eventSettings[uuid]) {
          newEventSettings[uuid] = eventSettings[uuid];
          (newEventSettings[uuid].dayKey = eventNode.insertDayKey),
            (newEventSettings[uuid].eventName = eventNode.model.eventName);
          if (newEventSettings[uuid].visible == undefined) {
            newEventSettings[uuid].visible = true;
          }
          if (!newEventSettings[uuid].registration) {
            newEventSettings[uuid].registration = {
              registrationSubject: "",
              registrationBody: "",
            };
          }
          if (eventNode.isEncore != undefined) {
            newEventSettings[uuid].isEncore = eventNode.isEncore;
          }
        } else {
          newEventSettings[uuid] = {
            dayKey: getInsertDayKey(eventNode.insertDayKey),
            registration: {
              registrationSubject: "",
              registrationBody: "",
            },
            visible: true,
            eventName: eventNode.model.eventName,
            webinarType: "automatic",
            webinarTemplate: "",
            meetingTemplate: "",
            videoFile: "",
            manualLength: 0,
            scheduleLength: 1,
            startAfter: 0,
            manualControl: {
              enabled: false,
              duration: 0,
            },
            advanced: {
              enabled: false,
              appendIntro: "",
              appendOutro: "",
            },
            highDefinition: {
              enabled: false,
            },
            secondaryVideo: {
              enabled: false,
              video: null,
            },
            splitVideo: {
              enabled: false,
              videos: [],
            },
            countdown: {
              enabled: false,
              duration: 15,
              countdown: defaultCount,
            },
          };
          if (eventNode.isEncore != undefined) {
            newEventSettings[uuid].isEncore = eventNode.isEncore;
          }
        }
      });
      // If multievent, then registration type needs to be 'one-time'
      // if (Object.keys(newEventSettings).length > 0) {
      //   this.registration.type = "one-time";
      // }
      this.general.multiEvents = {
        ...this.general.multiEvents,
        settings: newEventSettings,
      };
    },
    getEventNodes(time) {
      const arr = [];
      const nodes = this.general.multiEvents.nodes;
      const values = Object.values(nodes);
      const hasNaN = values.some((value) => isNaN(value));
      if (
        Object.values(this.general.multiEvents.nodes).length == 0 ||
        // time == undefined ||
        !hasNaN
      )
        return arr;
      Object.values(this.general.multiEvents.nodes).forEach((node, key) => {
        node.forEach((innerNode, key) => {
          const date = new Date(time);
          const checkKey = parseInt(innerNode.key);
          const days = parseInt(innerNode.days);
          if (innerNode.hours == null || innerNode.hours == "") {
            innerNode.hours = "00";
          } else if (innerNode.minutes == null || innerNode.minutes == "") {
            innerNode.minutes = "00";
          }

          let relativeTimeStr = `${days} day`;

          if (checkKey < 0) {
            date.setDate(date.getDate() - parseInt(days));
          } else if (checkKey > 0) {
            date.setDate(date.getDate() + parseInt(days));
          } else {
            relativeTimeStr = "";
          }

          if (innerNode.isExact) {
            date.setHours(0, 0, 0, 0);
          }
          if (innerNode.hours !== "" && innerNode.hours !== null) {
            const adjustedHours = innerNode.isExact
              ? parseInt(innerNode.amPm) === 1
                ? parseInt(innerNode.hours) === 12
                  ? 12 // If it's 12 AM, set hours to 0
                  : parseInt(innerNode.hours) + 12
                : parseInt(innerNode.hours) == 12
                ? 0 // If it's 12 PM, set hours to 12
                : innerNode.hours
              : parseInt(innerNode.isAfter) === 1
              ? date.getHours() + parseInt(innerNode.hours)
              : date.getHours() - parseInt(innerNode.hours) - 1;
            date.setHours(Math.max(0, Math.min(23, adjustedHours)));
            if (innerNode.hours !== "00") {
              relativeTimeStr += " " + innerNode.hours + " HR";
            }
          }

          // date.setHours(Math.max(0, Math.min(23, adjustedHours)));

          if (innerNode.minutes !== "" && innerNode.minutes !== null) {
            const adjustedMinutes = innerNode.isExact
              ? parseInt(innerNode.minutes)
              : parseInt(innerNode.isAfter) === 1
              ? date.getMinutes() + parseInt(innerNode.minutes)
              : date.getMinutes() -
                parseInt(innerNode.minutes == 0 ? 1 : innerNode.minutes);

            // Ensure adjustedMinutes is within the valid range (0 to 59)
            date.setMinutes(Math.max(0, Math.min(59, adjustedMinutes)));

            if (innerNode.minutes != "00") {
              relativeTimeStr +=
                " " +
                innerNode.minutes +
                `${parseInt(innerNode.minutes) == 1 ? " min" : " mins"}`;
            }
          }

          let description = getActionDescription(
            innerNode.action,
            innerNode.model
          );
          const obj = {
            title: `- ${innerNode.model?.eventName} Audience : ${innerNode.groupName} From ${innerNode.model.fromEventName}`,
            date: date,
            display: "month",
            eventId: innerNode.id,
            key: innerNode.key < 0 ? innerNode.key - 1 : innerNode.key,
            relativeTime:
              innerNode.isAfter == 1
                ? `${relativeTimeStr} after`
                : `${relativeTimeStr} before`,
            isExact: innerNode.isExact,
            days: days,
            groupName: innerNode.groupName,
            currentDate: date,
            integrationName:
              innerNode.actionTitle == "Add Another Event"
                ? "Multi Event"
                : innerNode.integrationName,
            backgroundColor: "green",
            description: description,
          };
          arr.push(obj);
        });
      });
      return arr;
    },
    async deleteEvent(node, timelineID) {
      let eventNodes = this.general.multiEvents.nodes;
      eventNodes = removeNodeByID(eventNodes, node.id ? node.id : node.eventId);
      // here we are removing the key if its empty
      eventNodes = Object.keys(eventNodes)
        .filter((key) => eventNodes[key].length > 0)
        .reduce((obj, key) => {
          obj[key] = eventNodes[key];
          return obj;
        }, {});
      this.general.multiEvents.nodes = eventNodes;
      this.refreshEventSetting();
      await this.setTimelinesMaster(timelineID);
      await this.fetchSettings(timelineID, "general")
    },
  },
  getters: {
    autoresponderList: (state) =>
      formatAutoresponder(state.autoresponder, state.autoresponder),
    registrationSettings: (state) => state.registration,
    generalSettings: (state) => state.general,
    pageSettings: (state) => state.page,
    getLanguages: (state) => state.languages,
    getCustomFieldTypes: (state) => state.customFieldTypes,
    getCustomFieldTemplates: (state) => state.customFieldTemplates,
    getPersonalizeFields: (state) => state.personalizeFields,
    getIntegrations: (state) => state.integration,
    registrationCountdown: (state) => {
      if (state?.page?.registration?.countdown?.timer.display) {
        return state.page.registration.countdown;
      } else {
        // Set  value in state.page.registration.countdown
        state.page.registration.countdown = defaultRegCountdown?.countdown;
        return state.page.registration.countdown;
      }
    },
    confirmationCountdown: (state) => {
      if (state?.page?.confirmation?.countdown?.timer.display) {
        return state.page.confirmation.countdown;
      } else {
        // Set value in state.page.confirmation.countdown
        state.page.confirmation.countdown = defaultCountdown?.countdown;
        return state.page.confirmation.countdown;
      }
    },
    replayCountdown: (state) => {
      if (state?.page?.replay?.countdown?.timer.display) {
        return state.page.replay.countdown;
      } else {
        // Set countdown value in state.page.replay.countdown
        state.page.replay.countdown = defaultCountdown?.countdown;
        return state.page.replay.countdown;
      }
    },
    pixelCountdown: (state) => {
      if (state?.page?.pixel?.countdown?.timer.display) {
        return state.page.pixel.countdown;
      } else {
        // Set countdown value in state.page.pixel.countdown
        state.page.pixel.countdown = defaultCountdown?.countdown;
        return state.page.pixel.countdown;
      }
    },
    mainCountdown: (state) => {
      if (state?.page?.countdown?.countdown?.timer.display) {
        return state.page.countdown.countdown;
      } else {
        // Set countdown value in state.page.countdown.countdown
        state.page.countdown.countdown = defaultCountdown?.countdown;
        return state.page.countdown.countdown;
      }
    },
    getRegistrationSetupType: (state) => {
      return state?.registration?.setupType ?? "page";
    },
    allEvents: (state) => {
      return getInnerEvents(state.general);
    },
  },
});

export const formatAutoresponder = (origin, model) => {
  let emails_keys = [
    "confirmation_title",
    "confirmation_body",
    "nonattendee_title",
    "nonattendee_body",
    "reminder_title",
    "reminder_body",
    "attendee_title",
    "attendee_body",
  ];
  emails_keys.map((email_key) => {
    model[email_key] = decodeURIAll(origin[email_key]);
    if (model[email_key] == "undefined") {
      model[email_key] = "";
    }
    if (origin.multiEvents) {
      Object.keys(origin.multiEvents).map((eventKey) => {
        if (origin.multiEvents[eventKey][email_key]) {
          model.multiEvents[eventKey][email_key] = decodeURIAll(
            origin.multiEvents[eventKey][email_key]
          );
        }
      });
    }
  });
  return model;
};

export const defaultCount = {
  layout: ["headline", "sub-headline", "timer-text", "media", "countdown"],
  media: {
    enabled: "true",
    type: "video",
    data: 0,
  },

  headline: {
    enabled: "true",
    title: "Headline",
    content: "This is your Opening Room Headline",
    style: {
      display: true,
      color: {
        hsl: {
          h: 0,
          s: 0,
          l: 0,
          a: 1,
        },
        hex: "#000",
        hex8: "#000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 1,
          a: 1,
        },
        oldHue: 0,
        source: "rgba",
        a: 1,
      },
      "background-color": {
        hsl: {
          h: 0,
          s: 0,
          l: 0,
          a: 0,
        },
        hex: "#000000",
        hex8: "#00000000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 0,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 0,
          a: 0,
        },
        oldHue: 0,
        source: "hex",
        a: 0,
      },
      "background-image":
        "https://cdn.aevent.com/icons/template_preview_bg_image.svg",
      "font-family": "sans-serif",
      "font-weight": "bold",
      "text-transform": "initial",
      "font-size": "42",
      "letter-spacing": "0",
      "text-align": "center",
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    styleString:
      "display:;border-color:#000;color:#000;background-color:#fff;font-family:sans-serif;font-weight:bold;text-transform:initial;font-size:42px;letter-spacing:0px;text-align:center;padding:0px 0px 0px 0px ;margin:0px 0px 0px 0px ;",
    color: {
      hsl: {
        h: 0,
        s: 0,
        l: 1,
        a: 1,
      },
      hex: "#FFFFFF",
      hex8: "#FFFFFFFF",
      rgba: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 1,
        a: 1,
      },
      oldHue: 0,
      source: "rgba",
      a: 1,
    },
    "background-color": {
      hsl: {
        h: 0,
        s: 0,
        l: 0,
        a: 0,
      },
      hex: "#00FFFFFF",
      hex8: "#00000000",
      rgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 0,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 0,
        a: 0,
      },
      oldHue: 0,
      source: "hex",
      a: 0,
    },
    "font-family": "sans-serif",
    "font-weight": "normal",
    "text-transform": "initial",
    "font-size": "26",
    "letter-spacing": "0",
    "text-align": "center",
    display: true,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  "sub-headline": {
    enabled: "true",
    title: "Sub-Headline",
    content: "This is your Sub Headline",
    style: {
      display: true,
      color: {
        hsl: {
          h: 0,
          s: 0,
          l: 0,
          a: 1,
        },
        hex: "#000",
        hex8: "#000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 0,
          a: 1,
        },
        oldHue: 0,
        source: "hsva",
        a: 1,
      },
      "background-color": {
        hsl: {
          h: 0,
          s: 0.6913319238900634,
          l: 0.49735950000000007,
          a: 0,
        },
        hex: "#D7272700",
        hex8: "#D7272700",
        rgba: {
          r: 215,
          g: 39,
          b: 39,
          a: 0,
        },
        hsv: {
          h: 0,
          s: 0.8174999999999999,
          v: 0.8412000000000001,
          a: 0,
        },
        oldHue: 0,
        source: "hsva",
        a: 0,
      },
      "font-family": "sans-serif",
      "font-weight": "normal",
      "text-transform": "initial",
      "font-size": "34",
      "letter-spacing": "0",
      "text-align": "center",
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    styleString:
      "display:;color:#000;background-color:#fff;font-family:sans-serif;font-weight:normal;text-transform:initial;font-size:34px;letter-spacing:0px;text-align:center;padding:0px 0px 0px 0px ;margin:0px 0px 0px 0px ;",
    color: {
      hsl: {
        h: 0,
        s: 0,
        l: 1,
        a: 1,
      },
      hex: "#FFFFFF",
      hex8: "#FFFFFFFF",
      rgba: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 1,
        a: 1,
      },
      oldHue: 0,
      source: "rgba",
      a: 1,
    },
    "background-color": {
      hsl: {
        h: 0,
        s: 0,
        l: 0,
        a: 0,
      },
      hex: "#00FFFFFF",
      hex8: "#00000000",
      rgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 0,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 0,
        a: 0,
      },
      oldHue: 0,
      source: "hex",
      a: 0,
    },
    "font-family": "sans-serif",
    "font-weight": "normal",
    "text-transform": "initial",
    "font-size": "26",
    "letter-spacing": "0",
    "text-align": "center",
    display: true,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  "timer-text": {
    enabled: "true",
    title: "Timer Text",
    content: "Starting in",
    style: {
      display: true,
      color: {
        hsl: {
          h: 0,
          s: 0,
          l: 1,
          a: 1,
        },
        hex: "#000",
        hex8: "#000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 1,
          a: 1,
        },
        oldHue: 0,
        source: "rgba",
        a: 1,
      },
      "background-color": {
        hsl: {
          h: 0,
          s: 0,
          l: 0,
          a: 0,
        },
        hex: "#000000",
        hex8: "#00000000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 0,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 0,
          a: 0,
        },
        oldHue: 0,
        source: "hex",
        a: 0,
      },
      "font-family": "sans-serif",
      "font-weight": "normal",
      "text-transform": "initial",
      "font-size": "26",
      "letter-spacing": "0",
      "text-align": "center",
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    styleString:
      "display:;border-color:#FFFFFFFF;color:#000;background-color:#fff;font-family:sans-serif;font-weight:normal;text-transform:initial;font-size:26px;letter-spacing:0px;text-align:center;padding:0px 0px 0px 0px ;margin:0px 0px 0px 0px ;",
    color: {
      hsl: {
        h: 0,
        s: 0,
        l: 1,
        a: 1,
      },
      hex: "#FFFFFF",
      hex8: "#FFFFFFFF",
      rgba: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 1,
        a: 1,
      },
      oldHue: 0,
      source: "rgba",
      a: 1,
    },
    "background-color": {
      hsl: {
        h: 0,
        s: 0,
        l: 0,
        a: 0,
      },
      hex: "#000000",
      hex8: "#00000000",
      rgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 0,
      },
      hsv: {
        h: 0,
        s: 0,
        v: 0,
        a: 0,
      },
      oldHue: 0,
      source: "hex",
      a: 0,
    },
    "font-family": "sans-serif",
    "font-weight": "normal",
    "text-transform": "initial",
    "font-size": "26",
    "letter-spacing": "0",
    "text-align": "center",
    display: true,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  "background-color": {
    hex: "#fff",
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  },
  countdown: {
    style: "2",
    enabled: "true",
    timer: {
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      color: {
        hsl: {
          h: 1,
          s: 0,
          l: 1,
          a: 1,
        },
        hex: "#000",
        hex8: "#000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 0,
          a: 1,
        },
        oldHue: 1,
        source: "rgba",
        a: "1",
      },
      "background-color": {
        hsl: {
          h: 1,
          s: 0,
          l: 1,
          a: 1,
        },
        hex: "#ffffff",
        hex8: "#ffffffff",
        rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 0,
        },
        hsv: {
          h: 1,
          s: 0,
          v: 1,
          a: 1,
        },
        oldHue: 0,
        source: "hex",
        a: 0,
      },
      "font-family": "sans-serif",
      "font-weight": "bold",
      "text-transform": "initial",
      "font-size": "22",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;border-color:#000;border-style:solid;border-width:4px;border-radius:50%;color:rgba(0,0,0,1)!important;background-color:rgba(255,255,255,1)!important;font-family:sans-serif!important;font-weight:bold!important;text-transform:initial!important;font-size:22px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
    period: {
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      color: {
        hsl: {
          h: 1,
          s: 0,
          l: 1,
          a: 1,
        },
        hex: "#000",
        hex8: "#00000000",
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        hsv: {
          h: 1,
          s: 0,
          v: 1,
          a: 1,
        },
        oldHue: 1,
        source: "rgba",
        a: "1",
      },
      "background-color": {
        hsl: {
          h: 0,
          s: 0,
          l: 0.4470588235294118,
          a: 1,
        },
        hex: "#ffffff",
        hex8: "#727272FF",
        rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 0,
        },
        hsv: {
          h: 0,
          s: 0,
          v: 0.4470588235294118,
          a: 1,
        },
        oldHue: 0,
        source: "hex",
        a: 1,
      },
      "font-family": "sans-serif",
      "font-weight": "bold",
      "text-transform": "uppercase",
      "font-size": "10",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;color:rgba(0,0,0,1)!important;background-color:rgba(255,255,255,0)!important;font-family:sans-serif!important;font-weight:bold!important;text-transform:uppercase!important;font-size:10px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
  },
  style: "2",
};

export const defaultCountdown = {
  countdown: {
    style: "1",
    enabled: "true",
    timer: {
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      color: {
        hsl: { h: 1, s: 0, l: 1, a: 1 },
        hex: "#FFFFFF",
        hex8: "#FFFFFFFF",
        rgba: { r: 255, g: 255, b: 255, a: 1 },
        hsv: { h: 1, s: 0, v: 1, a: 1 },
        oldHue: 1,
        source: "rgba",
        a: "1",
      },
      "background-color": {
        hsl: { h: 1, s: 0, l: 1, a: 1 },
        hex: "#ffffff",
        hex8: "#ffffffff",
        rgba: { r: 255, g: 255, b: 255, a: 0 },
        hsv: { h: 1, s: 0, v: 1, a: 1 },
        oldHue: 0,
        source: "hex",
        a: 0,
      },
      "font-family": "sans-serif",
      "font-weight": "normal",
      "text-transform": "initial",
      "font-size": "26",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;color:rgba(255,255,255,1)!important;background-color:rgba(0,0,0,0)!important;font-family:sans-serif!important;font-weight:normal!important;text-transform:initial!important;font-size:26px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
    period: {
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      color: {
        hsl: { h: 1, s: 0, l: 1, a: 1 },
        hex: "#FFFFFF",
        hex8: "#FFFFFFFF",
        rgba: { r: 255, g: 255, b: 255, a: 1 },
        hsv: { h: 1, s: 0, v: 1, a: 1 },
        oldHue: 1,
        source: "rgba",
        a: "1",
      },
      "background-color": {
        hsl: { h: 0, s: 0, l: 0.4470588235294118, a: 1 },
        hex: "#727272",
        hex8: "#727272FF",
        rgba: { r: 114, g: 114, b: 114, a: 1 },
        hsv: { h: 0, s: 0, v: 0.4470588235294118, a: 1 },
        oldHue: 0,
        source: "hex",
        a: 1,
      },
      "font-family": "sans-serif",
      "font-weight": "bold",
      "text-transform": "uppercase",
      "font-size": "10",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;color:rgba(255,255,255,1)!important;background-color:rgba(114,114,114,1)!important;font-family:sans-serif!important;font-weight:bold!important;text-transform:uppercase!important;font-size:10px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
  },
};
export const defaultRegCountdown = {
  countdown: {
    style: "1",
    enabled: "true",
    timer: {
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      color: {
        hsl: {
          h: 201.42857142857156,
          s: 0.8232907168643006,
          l: 0.79231425,
          a: 1,
        },
        hex: "#9ED6F6",
        hex8: "#9ED6F6FF",
        rgba: { r: 158, g: 214, b: 246, a: 1 },
        hsv: { h: 201.42857142857156, s: 0.355, v: 0.9632999999999999, a: 1 },
        oldHue: 201.42857142857156,
        source: "hsva",
        a: 1,
      },
      "background-color": {
        hsl: {
          h: 241.07142857142858,
          s: 0.48975791433891985,
          l: 0.25279275,
          a: 0,
        },
        hex: "#222160",
        hex8: "#22216000",
        rgba: { r: 34, g: 33, b: 96, a: 1 },
        hsv: { h: 241.07142857142858, s: 0.6575, v: 0.3766, a: 0 },
        oldHue: 241.07142857142858,
        source: "hsva",
        a: 0,
      },
      "font-family": "sans-serif",
      "font-weight": "normal",
      "text-transform": "initial",
      "font-size": "26",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;color:rgba(158,214,246,1)!important;background-color:rgba(34,33,96,1)!important;font-family:sans-serif!important;font-weight:normal!important;text-transform:initial!important;font-size:26px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
    period: {
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      color: {
        hsl: { h: 1, s: 0, l: 1, a: 1 },
        hex: "#FFFFFF",
        hex8: "#FFFFFFFF",
        rgba: { r: 255, g: 255, b: 255, a: 1 },
        hsv: { h: 1, s: 0, v: 1, a: 1 },
        oldHue: 1,
        source: "rgba",
        a: "1",
      },
      "background-color": {
        hsl: { h: 0, s: 0, l: 0.4470588235294118, a: 1 },
        hex: "#727272",
        hex8: "#727272FF",
        rgba: { r: 114, g: 114, b: 114, a: 1 },
        hsv: { h: 0, s: 0, v: 0.4470588235294118, a: 1 },
        oldHue: 0,
        source: "hex",
        a: 1,
      },
      "font-family": "sans-serif",
      "font-weight": "bold",
      "text-transform": "uppercase",
      "font-size": "10",
      "letter-spacing": "0",
      "text-align": "center",
      display: true,
      styleString:
        "margin:!important;padding:!important;color:rgba(255,255,255,1)!important;background-color:rgba(114,114,114,1)!important;font-family:sans-serif!important;font-weight:bold!important;text-transform:uppercase!important;font-size:10px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
    },
  },
  integration: "standard",
};
// {
//   countdown: {
//     style: "0",
//     enabled: "true",
//     timer: {
//       margin: {
//         top: 0,
//         right: 0,
//         bottom: 0,
//         left: 0,
//       },
//       padding: {
//         top: 0,
//         right: 0,
//         bottom: 0,
//         left: 0,
//       },
//       color: {
//         hsl: { h: 1, s: 0, l: 1, a: 1 },
//         hex: "#FFFFFF",
//         hex8: "#FFFFFFFF",
//         rgba: { r: 255, g: 255, b: 255, a: 1 },
//         hsv: { h: 1, s: 0, v: 1, a: 1 },
//         oldHue: 1,
//         source: "rgba",
//         a: "1",
//       },
//       "background-color": {
//         hsl: { h: 0, s: 0, l: 0, a: 0 },
//         hex: "#000000",
//         hex8: "#00000000",
//         rgba: { r: 0, g: 0, b: 0, a: 0 },
//         hsv: { h: 0, s: 0, v: 0, a: 0 },
//         oldHue: 0,
//         source: "hex",
//         a: 0,
//       },
//       "font-family": "sans-serif",
//       "font-weight": "normal",
//       "text-transform": "initial",
//       "font-size": "26",
//       "letter-spacing": "0",
//       "text-align": "center",
//       display: true,
//       styleString:
//         "margin:!important;padding:!important;color:rgba(255,255,255,1)!important;background-color:rgba(0,0,0,1)!important;font-family:sans-serif!important;font-weight:normal!important;text-transform:initial!important;font-size:12px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
//     },
//     period: {
//       margin: {
//         top: 0,
//         right: 0,
//         bottom: 0,
//         left: 0,
//       },
//       padding: {
//         top: 0,
//         right: 0,
//         bottom: 0,
//         left: 0,
//       },
//       color: {
//         hsl: { h: 1, s: 0, l: 1, a: 1 },
//         hex: "#FFFFFF",
//         hex8: "#FFFFFFFF",
//         rgba: { r: 255, g: 255, b: 255, a: 1 },
//         hsv: { h: 1, s: 0, v: 1, a: 1 },
//         oldHue: 1,
//         source: "rgba",
//         a: "1",
//       },
//       "background-color": {
//         hsl: { h: 0, s: 0, l: 0.4470588235294118, a: 1 },
//         hex: "#727272",
//         hex8: "#727272FF",
//         rgba: { r: 114, g: 114, b: 114, a: 1 },
//         hsv: { h: 0, s: 0, v: 0.4470588235294118, a: 1 },
//         oldHue: 0,
//         source: "hex",
//         a: 1,
//       },
//       "font-family": "sans-serif",
//       "font-weight": "bold",
//       "text-transform": "uppercase",
//       "font-size": "10",
//       "letter-spacing": "0",
//       "text-align": "center",
//       display: true,
//       styleString:
//         "margin:!important;padding:!important;color:rgba(255,255,255,1)!important;background-color:rgba(0,0,0,1)!important;font-family:sans-serif!important;font-weight:normal!important;text-transform:initial!important;font-size:12px!important;letter-spacing:0px!important;text-align:center!important;display:true!important;",
//     },
//   },
// };
