import { defineStore } from "pinia";

export const useCommonStore = defineStore({
  id: "common-store",
  state: () => {
    return {
      allLoaded: false,
      activeTab: "index",
      isDark: false,
      reconnectIntegration: null,
      uploadMediaModal: false,
      uploadMediaModalMinimize: false,
      templateData:[],
      editBuilder: {
        open: false,
        data: {},
        id: "",
        continue: true,
        useTemplate: false
      },
      timelineData: {
        integration: [],
        general: {
          timelineName: "",
          description: "",
          deliveryPlatform: "",
          webinarType: "",
          eventType: "",
          timeStamp: null,
          timeZone: "America/Los_Angeles",
          visible: true,
          templateType: "",
          language: "",
          organizer:[],
          highDefinition: {
            enabled: false,
          },
          userGroup: [
            {
              name: "Registrants",
              id: "3",
              conditions: [],
            },
            {
              name: "Attendee",
              id: "a1",
              conditions: [
                {
                  if: "attended-hour",
                  condition: ">",
                  duration: "0",
                },
              ],
            },
            {
              name: "Non-Attendee",
              id: "a2",
              conditions: [
                {
                  if: "attended-hour",
                  condition: "=",
                  duration: "0",
                },
              ],
            },
          ],
          presenters: [],
        },
        autoresponder: {
          integrations: [],
          webinarEnabled: "false",
          confirmationEnabled: "false",
          reminderEnabled: "false",
          nonattendeeEnabled: "false",
          attendeeEnabled: "false",
          multiEvents: {},
          confirmation_title: "",
          confirmation_body: "",
          nonattendee_title: "",
          nonattendee_body: "",
          reminder_title: "",
          reminder_body: "",
          attendee_title: "",
          attendee_body: "",
        },
        registration: {
          customForms: [],
          isInnerPageEnabled: false,
          type: "multi-option",
          setupType: "page",
          registrationBody: "",
          registrationSubject: "",
        },
      },
    };
  },
  actions: {
    async setAllLoaded(value) {
      this.allLoaded = value;
    },
    async setuploadMediaModal(value) {
      this.uploadMediaModal = value;
    },
    async setuploadMediaModalMinimize(value) {
      this.uploadMediaModalMinimize = value;
    },
    async setActiveTab(value) {
      this.activeTab = value;
    },
    setBuilderEditor(value) {
      this.editBuilder = value;
    },
    setDarkMode(value) {
      this.isDark = value;
    },
    setTimeLineData(value) {
      this.timelineData = value;
    },
    setTemplateData(value) {
      this.templateData = value;
    },
    async refreshSupportToken() {
      const response = await call("/user/support/refresh", "POST");
      if (response && response["success"]) {
        const { success } = response;
      }
      return response;
    },
  },
  getters: {
    isAllLoaded: (state) => state.allLoaded,
    isActiveTab: (state) => state.activeTab,
    checkDark: (state) => state.isDark,
    getTimelineData: (state) => state.timelineData,
    isuploadMediaModal: (state) => state.uploadMediaModal,
    isuploadMediaModalMinimize: (state) => state.uploadMediaModalMinimize,
    getEditBuilder: (state) => state.editBuilder,
    getTemplateData: (state) => state.templateData
  },
});
