import { defineNitroPlugin } from 'nitropack/dist/runtime/plugin';

export default defineNitroPlugin((nitroApp) => {
  nitroApp.hooks.hook("render:html", async (html, { event }) => {
      const nonce = event.context?.security?.nonce;
      if (!nonce) return;
      html.bodyAppend = html.bodyAppend.map((element) => {
          return element.replace("!runtimeNonce!", nonce);
      });
  });
})

