const postRequest = (url, payload, headers) => {
  const nuxtAppInstance = useNuxtApp();
  return nuxtAppInstance.$api.post(url, payload, { headers: headers });
};

const getRequest = (url, payload, headers) => {
  const nuxtAppInstance = useNuxtApp();
  return nuxtAppInstance.$api.get(url, { headers: headers });
};

const deleteRequest = (url, payload, headers) => {
  const nuxtAppInstance = useNuxtApp();
  return nuxtAppInstance.$api.delete(url, { headers: headers });
};

const putRequest = (url, payload, headers) => {
  const nuxtAppInstance = useNuxtApp();
  return nuxtAppInstance.$api.put(url, payload, { headers: headers });
};

const patchRequest = (url, payload, headers) => {
  const nuxtAppInstance = useNuxtApp();
  return nuxtAppInstance.$api.patch(url, payload, { headers: headers });
};

export const call = async (apiResource, httpMethod, payload = {}, Error) => {
  const headers = {
    "Content-Type": "application/json",
    accept: "application/json",
  };

  if (apiResource !== "/login")
    headers.authorization = `Bearer ${useCookie("token").value}`;
  // if (httpMethod == "POST" || httpMethod == "PUT") options.body = payload;
  let method =
    httpMethod == "GET"
      ? getRequest
      : httpMethod == "DELETE"
      ? deleteRequest
      : httpMethod == "PUT"
      ? putRequest
      : httpMethod == "PATCH"
      ? patchRequest
      : postRequest;

  try {
    const response = await method(`${apiResource}`, payload, headers);
    const ret =
      apiResource == "/webinartimeline/delete"
        ? response
        : response.status == 200
        ? response.data
        : response;

    return ret;
  } catch (error) {
    if (Error || Error === "sales") {
      return { error }; 
    } else {
      return false;
    }
  }
};

let DEFAULT_GROUP = [
  {
    name: "Registrants",
    id: "3",
    conditions: [],
  },
  {
    name: "Attendee",
    id: "a1",
    conditions: [
      {
        if: "attended-hour",
        condition: ">",
        duration: "0",
      },
    ],
  },
  {
    name: "Non-Attendee",
    id: "a2",
    conditions: [
      {
        if: "attended-hour",
        condition: "=",
        duration: "0",
      },
    ],
  },
];

export const getDefaultGroup = () => {
  return JSON.parse(JSON.stringify(DEFAULT_GROUP));
};
