import { default as _91integration_93callbackrn4eBpKeQjMeta } from "/usr/src/nuxt-app/pages/[integration]callback.vue?macro=true";
import { default as _404Am7IRVzZLlMeta } from "/usr/src/nuxt-app/pages/404.vue?macro=true";
import { default as changePasswordy6KgeWnsgDMeta } from "/usr/src/nuxt-app/pages/changePassword.vue?macro=true";
import { default as indexA7wFPJ7AtyMeta } from "/usr/src/nuxt-app/pages/chart/index.vue?macro=true";
import { default as createVzRI0lbikaMeta } from "/usr/src/nuxt-app/pages/create.vue?macro=true";
import { default as developerKHCoyggNDUMeta } from "/usr/src/nuxt-app/pages/developer.vue?macro=true";
import { default as indexzULVd8JPYfMeta } from "/usr/src/nuxt-app/pages/disabled/index.vue?macro=true";
import { default as forgotPasswordMdMTSAojxdMeta } from "/usr/src/nuxt-app/pages/forgotPassword.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as indexsWUW61C40cMeta } from "/usr/src/nuxt-app/pages/integration/index.vue?macro=true";
import { default as add_45integration7tvIiUz174Meta } from "/usr/src/nuxt-app/pages/integrations/add-integration.vue?macro=true";
import { default as indexp8q8IWCZ5aMeta } from "/usr/src/nuxt-app/pages/integrations/index.vue?macro=true";
import { default as invokerizjqjMCbEoMeta } from "/usr/src/nuxt-app/pages/invoker.vue?macro=true";
import { default as landingtYvM9RYcEsMeta } from "/usr/src/nuxt-app/pages/landing.vue?macro=true";
import { default as index6gMUc1WfHDMeta } from "/usr/src/nuxt-app/pages/leads/index.vue?macro=true";
import { default as indexuSsRSTZYjlMeta } from "/usr/src/nuxt-app/pages/login/index.vue?macro=true";
import { default as indexI3wfkpcb7IMeta } from "/usr/src/nuxt-app/pages/media-new/audios/index.vue?macro=true";
import { default as indexy3NhsordMkMeta } from "/usr/src/nuxt-app/pages/media-new/images/index.vue?macro=true";
import { default as indexBZTw6Z1SvIMeta } from "/usr/src/nuxt-app/pages/media-new/index.vue?macro=true";
import { default as indexW2zZHHhQIgMeta } from "/usr/src/nuxt-app/pages/media-new/niftyImages/index.vue?macro=true";
import { default as indexyUDgveq7i9Meta } from "/usr/src/nuxt-app/pages/media-new/videos/index.vue?macro=true";
import { default as index92D7dKRF6QMeta } from "/usr/src/nuxt-app/pages/media/index.vue?macro=true";
import { default as indexdFbAjk1zJQMeta } from "/usr/src/nuxt-app/pages/settings/index.vue?macro=true";
import { default as timelineErrorzBQGEizyfbMeta } from "/usr/src/nuxt-app/pages/timelineError.vue?macro=true";
import { default as _91id_93labvqhSrDJMeta } from "/usr/src/nuxt-app/pages/timelines/[id].vue?macro=true";
import { default as indexMqvft16FPiMeta } from "/usr/src/nuxt-app/pages/timelines/index.vue?macro=true";
import { default as wizardAWy1LCdHAzMeta } from "/usr/src/nuxt-app/pages/timelines/wizard.vue?macro=true";
import { default as _91id_93bWW8lyu8msMeta } from "/usr/src/nuxt-app/pages/webinar/[id].vue?macro=true";
import { default as schedule_backup1Ke5nyXAMiMeta } from "/usr/src/nuxt-app/pages/webinar/schedule_backup.vue?macro=true";
import { default as indexBfxHgXsivfMeta } from "/usr/src/nuxt-app/pages/webinar/schedule/index.vue?macro=true";
export default [
  {
    name: "integrationcallback___en-US",
    path: "/:integration()callback",
    meta: _91integration_93callbackrn4eBpKeQjMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/[integration]callback.vue")
  },
  {
    name: "404___en-US",
    path: "/404",
    component: () => import("/usr/src/nuxt-app/pages/404.vue")
  },
  {
    name: "changePassword___en-US",
    path: "/changePassword",
    component: () => import("/usr/src/nuxt-app/pages/changePassword.vue")
  },
  {
    name: "chart___en-US",
    path: "/chart",
    component: () => import("/usr/src/nuxt-app/pages/chart/index.vue")
  },
  {
    name: "create___en-US",
    path: "/create",
    component: () => import("/usr/src/nuxt-app/pages/create.vue")
  },
  {
    name: "developer___en-US",
    path: "/developer",
    meta: developerKHCoyggNDUMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/developer.vue")
  },
  {
    name: "disabled___en-US",
    path: "/disabled",
    component: () => import("/usr/src/nuxt-app/pages/disabled/index.vue")
  },
  {
    name: "forgotPassword___en-US",
    path: "/forgotPassword",
    component: () => import("/usr/src/nuxt-app/pages/forgotPassword.vue")
  },
  {
    name: "index___en-US",
    path: "/",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "integration___en-US",
    path: "/integration",
    meta: indexsWUW61C40cMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/integration/index.vue")
  },
  {
    name: "integrations-add-integration___en-US",
    path: "/integrations/add-integration",
    meta: add_45integration7tvIiUz174Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/integrations/add-integration.vue")
  },
  {
    name: "integrations___en-US",
    path: "/integrations",
    meta: indexp8q8IWCZ5aMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/integrations/index.vue")
  },
  {
    name: "invoker___en-US",
    path: "/invoker",
    component: () => import("/usr/src/nuxt-app/pages/invoker.vue")
  },
  {
    name: "landing___en-US",
    path: "/landing",
    component: () => import("/usr/src/nuxt-app/pages/landing.vue")
  },
  {
    name: "leads___en-US",
    path: "/leads",
    meta: index6gMUc1WfHDMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/leads/index.vue")
  },
  {
    name: "login___en-US",
    path: "/login",
    meta: indexuSsRSTZYjlMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/login/index.vue")
  },
  {
    name: "media-new-audios___en-US",
    path: "/media-new/audios",
    component: () => import("/usr/src/nuxt-app/pages/media-new/audios/index.vue")
  },
  {
    name: "media-new-images___en-US",
    path: "/media-new/images",
    component: () => import("/usr/src/nuxt-app/pages/media-new/images/index.vue")
  },
  {
    name: "media-new___en-US",
    path: "/media-new",
    meta: indexBZTw6Z1SvIMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/media-new/index.vue")
  },
  {
    name: "media-new-niftyImages___en-US",
    path: "/media-new/niftyImages",
    component: () => import("/usr/src/nuxt-app/pages/media-new/niftyImages/index.vue")
  },
  {
    name: "media-new-videos___en-US",
    path: "/media-new/videos",
    component: () => import("/usr/src/nuxt-app/pages/media-new/videos/index.vue")
  },
  {
    name: "media___en-US",
    path: "/media",
    meta: index92D7dKRF6QMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/media/index.vue")
  },
  {
    name: "settings___en-US",
    path: "/settings",
    meta: indexdFbAjk1zJQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/settings/index.vue")
  },
  {
    name: "timelineError___en-US",
    path: "/timelineError",
    component: () => import("/usr/src/nuxt-app/pages/timelineError.vue")
  },
  {
    name: "timelines-id___en-US",
    path: "/timelines/:id()",
    component: () => import("/usr/src/nuxt-app/pages/timelines/[id].vue")
  },
  {
    name: "timelines___en-US",
    path: "/timelines",
    meta: indexMqvft16FPiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/timelines/index.vue")
  },
  {
    name: "timelines-wizard___en-US",
    path: "/timelines/wizard",
    component: () => import("/usr/src/nuxt-app/pages/timelines/wizard.vue")
  },
  {
    name: "webinar-id___en-US",
    path: "/webinar/:id()",
    component: () => import("/usr/src/nuxt-app/pages/webinar/[id].vue")
  },
  {
    name: "webinar-schedule_backup___en-US",
    path: "/webinar/schedule_backup",
    meta: schedule_backup1Ke5nyXAMiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/webinar/schedule_backup.vue")
  },
  {
    name: "webinar-schedule___en-US",
    path: "/webinar/schedule",
    component: () => import("/usr/src/nuxt-app/pages/webinar/schedule/index.vue")
  }
]