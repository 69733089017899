import { defineStore } from "pinia";

export const useLeadsStore = defineStore({
  id: "leads-store",
  state: () => {
    return {
      leads: {},
      totalLeads: 0,
      bannedLeads: {},
      totalBannedLeads: 0,
    };
  },
  actions: {
    async fetchLeads(page = 0) {
      const response = await call(
        `/registrants?page=${page}&filter=all`,
        "GET"
      );
      let leadsResponse = false;
      if (response) {
        const { success, total } = response;
        this.leads = success;
        this.totalLeads = Math.round(total / 20);
        leadsResponse = this.leads;
      }
      // this.fetchBannedLeads();
      // return response.success;
    },
    async fetchSearchLeads(searchText, page = 0) {
      const response = await call(
        `/registrants?filter=${searchText}&page=${page}`,
        "GET"
      );
      let leadsResponse = false;
      if (response) {
        const { success, total } = response;
        this.leads = success;
        this.totalLeads = Math.round(total / 20);
        leadsResponse = this.leads;
      }
      return response;
    },
    async fetchBannedLeads(page = 0) {
      const response = await call(
        `/registrants?page=${page}&filter=banned`,
        "GET"
      );
      let leadsResponse = false;
      if (response) {
        const { success, total } = response;
        this.totalBannedLeads = Math.round(total / 20);
        this.bannedLeads = success;
        leadsResponse = this.bannedLeads;
      }
      return response.success;
    },
    async banLeadEmail(payload) {
      const response = await call("/registrants/ban", "POST", payload);
      if (response) {
        return response;
      }
    },
    async deleteLead(uniqueID) {
      const response = await call(`/registrants/${uniqueID}`, "DELETE");
      let deleteResponse = false;
      this.leads = Object.fromEntries(
        Object.entries(this.leads).filter((el) => el[1].uuid !== uniqueID)
      );
      deleteResponse = this.leads;
      return deleteResponse;
    },
  },
  getters: {
    leadsList: (state) => state.leads,
    totalPages: (state) => state.totalLeads,
    bannedLeadsList: (state) => state.bannedLeads,
    bannedTotalPages: (state) => state.totalBannedLeads,
  },
});
