<template>
  <div
    :class="[showToast === true ? 'right-[200px]' : '-right-[250px]']"
    class="z-[99999] bottom-20 bg-white dark:bg-neutral-800 delay-200 transition-all ease-in-out duration-500 max-w-[225px] fixed rounded-lg"
  >
    <div
      class="w-[400px] relative bg-white dark:bg-neutral-800 p-4 px-5 shadow-lg rounded-lg overflow-hidden"
      role="alert"
    >
      <div class="flex items-start justify-between w-full">
        <div class="flex flex-col items-start">
          <h3
            class="text-base text-neutral-800 font-semibold dark:text-neutral-200"
          >
          A new APP Deployment is available
          </h3>
          <p
            class="font-normal text-sm mt-1 text-neutral-600 dark:text-neutral-400"
          >
            To ensure the latest updates and a seamless experience, kindly
            refresh your browser. Thank you!
          </p>
        </div>

        <svg
          @click="showToast = false"
          class="cursor-pointer absolute right-4 hover:text-black"
          :class="'text-black dark:text-neutral-400'"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4L4 12"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4 4L12 12"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="flex justify-end mt-4 gap-3">
        <button
          @click="showToast = false"
          class="text-sm hover:bg-black/5 transition-all ease-in-out duration-500 text-black py-3 dark:text-neutral-200 px-4 rounded-md flex justify-end items-end"
        >
          Maybe later
        </button>
        <button
          @click="reloadPage"
          class="bg-black hover:bg-black/80 text-sm transition-all ease-in-out duration-500 dark:bg-black text-white py-3 px-4 rounded-md flex justify-end items-end"
        >
          Refresh
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  showToast: Object,
  message: String,
  label: String,
});
const reloadPage = () => {
  window?.location.reload();
};
</script>
