import { defineStore } from "pinia";
import { useCommonStore } from "./common";
import { useWebinarStore } from "~~/store/webinar";
import { useSettingsStore } from "~~/store/settings";
import { useIntegrationStore } from "~~/store/integration";
import { useLeadsStore } from "~~/store/leads";
import { useMediaStore } from "~~/store/media";
import { useTimelineStore } from "~~/store/timeline";

// import Parse from "parse/dist/parse.min.js";

// auth store
export const useAuthStore = defineStore({
  id: "auth-store",
  state: () => {
    return {
      user: {},
      resourceStatus: {},
      notifications: [],
      notificationSubscriberId: null,
      purchases: 0,
      action_used: 0,
      webinar_hours: 0,
      terms: "",
      additional_actions: 0,
      tokens: null,
      scopes: null,
      resource_history: null,
      intercomSettings: null,
      graph_data: [],
    };
  },
  actions: {
    async loginUser(email, password, rememberMe, accessToken = "") {
      const commonStore = useCommonStore();
      if (!accessToken) {
        const paylaod = {
          email: email,
          password: password,
        };
        const response = await call("/login", "POST", paylaod);
        if (response) {
          const { success } = response;
          this.user = success;
          localStorage.setItem("tour", success?.profile?.tours);
          localStorage.setItem("user_role_id", success?.role);

          this.isAuthenticated = true;
          setCookieCustom("token", success.api_token, rememberMe ? 30 : 1);
          if (!commonStore.isAllLoaded) await getData();
          this.prepareIntercom();
          return response;
        }
      } else {
        let formData = new FormData();
        formData.append("accessToken", accessToken);
        const response = await call("/login", "POST", formData);
        setCookieCustom("token", accessToken, rememberMe ? 30 : 1);
        this.isAuthenticated = true;
        if (!commonStore.isAllLoaded) await getData();
        if (response) {
          const { success } = response;
          this.user = success;
          localStorage.setItem("tour", success?.profile?.tours);
          localStorage.setItem("user_role_id", success?.role);

          this.prepareIntercom();
          return response;
        }
      }
    },
    async validate() {
      // const nuxtAPP=useNuxtApp();
      const response = await call("/validate", "POST");
      if (response) {
        const { success } = response;
        this.user = success;
        localStorage.setItem("tour", success?.profile?.tours);
        localStorage.setItem("user_role_id", success?.role);

        const url = new URL(window.location.href);
        this.prepareIntercom();
        return response;
      } else {
        const authCookie = useCookie("token");
        authCookie.value = null;
        const route = useRoute();

        if (
          route.path != "/landing" &&
          route.path != "/invoker" &&
          route.path != "/forgotPassword"
        )
          navigateTo("/login");
      }
      this.prepareIntercom();
      return response;
    },
    async logout() {
      const response = await call("/logout", "DELETE");
    },
    async fetchResourceStatus() {
      const response = await call("user/resource_status", "POST");
      if (response) {
        const { success } = response;
        this.resourceStatus = success;
        this.action_used = success.actions;
        this.webinar_hours = success.webinar_hours;
        this.recurring_timelines = success.recurring_timelines;
        this.additional_actions = success.additional_actions;
        this.graph_data = success.graphData;
      }
      return response;
    },
    async fetchResourceHistory(param) {
      const response = await call(`/user/resource_history?${param}`, "GET");
      if (response) {
        this.resource_history = response.success;
      }
      return response;
    },
    async updateUser(paylaod) {
      const response = await call("updateuser", "POST", paylaod);
      if (response) {
        const { success } = response;
        this.user = success;
      }
      return response;
    },
    async updateUserHelperTool(paylaod) {
      const response = await call("profile", "PUT", paylaod);
      if (response) {
        const { success } = response;
        localStorage.setItem("tour", success.tours);

        this.validate();
      }
      return response;
    },
    async checkpassword(password, newpass, change) {
      const paylaodpasswordChange = {
        password: password,
        newpass: newpass,
      };

      const response = await call(
        "checkpassword",
        "POST",
        change ? password : paylaodpasswordChange
      );
      if (response) {
        const { success } = response;
      }
      return response;
    },
    async fetchPurchases() {
      const response = await call("purchases", "GET");
      if (response?.length) {
        this.purchases = response
          .filter((purchase) => purchase?.type === "actions")
          .map((p) => parseInt(p.quantity))
          .filter((value) => !isNaN(value)) // Filter out NaN values
          .reduce((elem, acc) => elem + acc, 0);
      } else {
        this.purchases = 0;
      }
      return response;
    },
    removeNotification(notification) {
      notification.destroy().then(() => {
        this.notifications = this.notifications.filter((item) => {
          return notification.id != item.id;
        });
      });
    },
    async fetchTerms() {
      if (
        this.user.email &&
        !this.user.agreed_current_terms &&
        !this.terms?.length
      ) {
        this.terms = await call("terms_of_service", "GET");
        return this.terms;
      }
    },
    async checkToken(token) {
      const response = await call("checkforgottoken", "POST", token);

      return response;
    },
    async updateNewPassword(data) {
      const response = await call("changepassword", "POST", data);
      return response;
    },
    async agreeCurrentTerms() {
      await call("consent_terms", "POST");
      this.user.agreed_current_terms = true;
    },
    async setExperienceLevel(level) {
      await call("level", "POST", { level });
      this.user.profile.userSkillLevel = level;
    },
    async changeTheme(theme) {
      await call("updateTheme", "POST", { theme });
    },
    async fetchTokens() {
      this.tokens = await call("oauth/personal-access-tokens", "GET");
      return this.tokens;
    },
    async createToken({ name, scopes }) {
      const res = await call("oauth/personal-access-tokens", "POST", {
        name,
        scopes,
      });
      this.tokens.push(res.token);
      return res.accessToken;
    },
    async deleteToken(tokenID) {
      const res = await call(
        `oauth/personal-access-tokens/${tokenID}`,
        "DELETE"
      );
      this.tokens = this.tokens.filter((token) => token.id !== tokenID);
      return res;
    },
    async fetchScopes() {
      this.scopes = await call("oauth/scopes", "GET");
      return this.scopes;
    },
    async getPlanDetails() {
      try {
        const ret = await call("user/plan", "GET");
        return ret?.success ?? false;
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    async changePlan({ plan, frequency }) {
      try {
        const ret = await call("user/plan", "PUT", { plan, frequency }, true);
        if (ret.error) {
          return (
            ret.error?.response?.data?.error ??
            ret.error?.response?.data?.message ??
            "There was an error changing plans. Please reach out to support."
          );
        }
        if (ret.success) {
          this.user.role = plan;
          return true;
        }
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    async cancelPlan() {
      try {
        const ret = await call("user/plan", "DELETE", {}, true);
        if (ret.error) {
          return (
            ret.error?.response?.data?.error ??
            ret.error?.response?.data?.message ??
            "There was an error canceling your subscription. Please reach out to support."
          );
        }
        if (ret.success) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    async updatePayment({ id }) {
      try {
        const ret = await call("user/payment", "PUT", { id }, true);
        if (ret.error) {
          return (
            ret.error?.response?.data?.error ??
            ret.error?.response?.data?.message ??
            "There was an error updating your payment information. Please reach out to support."
          );
        }
        if (ret.success) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    async resetStores() {
      const mediaStore = useMediaStore();
      const webinarStore = useWebinarStore();
      const timelineStore = useTimelineStore();
      const settingsStore = useSettingsStore();
      const integrationStore = useIntegrationStore();
      const leadStore = useLeadsStore();
      const commonStore = useCommonStore();
      await mediaStore.$reset();
      await webinarStore.$reset();
      await timelineStore.$reset();
      await settingsStore.$reset();
      await integrationStore.$reset();
      await leadStore.$reset();
      await commonStore.$reset();
      window.intercomSettings = null;
      await this.$reset();
    },
    async prepareIntercom() {
      if (!this.user?.email) return;
      if (typeof this.resourceStatus.actions === "undefined") {
        await this.fetchResourceStatus();
      }
      const mediaStore = useMediaStore();
      const timelineStore = useTimelineStore();
      const currentSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "zz0osdbu",
        email: this.user?.email ?? "",
        user_hash: this.user?.intercom ?? "",
        name: this.user?.name ?? "",
        created_at: this.user?.created_at ?? "",
        plan_type: this.accountLevelString,
        storage_used: mediaStore.totalMediaSize(1),
        timelines_used: timelineStore.timelinesCount,
        actions_used: this.userActionUsed,
        webinar_hours: this.webinarHoursUsed,
        tenant_id: this.user?.domain ?? "",
        account_creation: this.user?.created_at?.substring(0, 10) ?? "",
      };
      this.intercomSettings = currentSettings;
      window.intercomSettings = currentSettings;
    },
  },
  getters: {
    getUser: (state) => {
      if (state?.user) {
        return state?.user;
      }
    },
    getResourceStatus: (state) => state.resourceStatus,
    getNotifications: (state) => state.notifications,

    awebinarUser: (state) => {
      return state?.user?.role === "5";
    },
    manualUser: (state) => {
      return state?.user?.role === "13";
    },
    betaUser: (state) => {
      return state.user?.beta === 1 ?? false;
    },
    hasRegistrantUpload: (state) => {
      return state.user?.features?.registrantUpload ?? false;
    },
    accountLevel: (state) => state?.user?.role ?? ACCOUNT_LEVEL.disabled,
    accountLevelString: (state) => {
      if (window.location.href.match(/:\/\/awebinar/)) return "awebinar";
      if (state.user) {
        let ret = "disabled";
        Object.keys(ACCOUNT_LEVEL).map((key) => {
          if (ACCOUNT_LEVEL[key] == state.user.role) {
            ret = key;
          }
        });
        return ret;
      }
      return "disabled";
    },
    userTimelineLimit: (state) => {
      if (state.user) {
        return TIMELINE_LIMIT[state.user.role];
      }
      return 0;
    },
    userHourLimit: (state) => {
      if (state.user) {
        return WEBINAR_HOUR_LIMIT[state?.user?.role];
        // return 60;
      }
      return 0;
    },
    userActionLimit: (state, getters) => {
      const baseLimit = ACTION_LIMIT[state?.user?.role] ?? 0;
      if (baseLimit < 0) return baseLimit;
      return baseLimit + state.purchases + state.additional_actions;
    },
    recurringTimelines: (state) => {
      return state.recurring_timelines ?? 0;
    },
    getResourceHistory: (state) => {
      return state.resource_history;
    },
    userActionUsed: (state) => {
      return state.action_used;
    },
    webinarHoursUsed: (state) => {
      return state.webinar_hours || 0;
    },
    storageLimitGB: (state) => {
      return STORAGE_LIMIT[state?.user?.role] ?? 0;
    },
    termsOfService: (state) => state?.terms ?? "",
    advanced: (state) => state?.user?.profile?.userSkillLevel != "beginner",
    expert: (state) => state?.user?.profile?.userSkillLevel == "expert",
    beginner: (state) => state?.user?.profile?.userSkillLevel == "beginner",
    getTokens: (state) => state.tokens,
    getScopes: (state) => state.scopes,
    showDisableRefresh: (state) =>
      new Date(state?.user?.created_at).getTime() < 1703007767150,
    disabledUser: (state) => state?.user?.role == -1,
    intercom: (state) => state.intercomSettings,
  },
});

export const setCookieCustom = (cname, cvalue, exdays = 1) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict";
};

export const ACCOUNT_LEVEL = {
  admin: "99",
  free: "00",
  beginner: "0",
  advanced: "1",
  professional: "2",
  unlimited: "3",
  disabled: "-1",
  awebinar: "5",
  manual: "13",
  support: "8",
  operator: "9",
  sales: "7",
};

export const TIMELINE_LIMIT = {
  [ACCOUNT_LEVEL.free]: 2,
  [ACCOUNT_LEVEL.beginner]: 20,
  [ACCOUNT_LEVEL.advanced]: 60,
  [ACCOUNT_LEVEL.professional]: -1,
  [ACCOUNT_LEVEL.admin]: -1,
  [ACCOUNT_LEVEL.unlimited]: -1,
  [ACCOUNT_LEVEL.awebinar]: 16,
  [ACCOUNT_LEVEL.manual]: 10,
  [ACCOUNT_LEVEL.operator]: -1,
  [ACCOUNT_LEVEL.support]: -1,
  [ACCOUNT_LEVEL.sales]: -1,
};

export const ACTION_LIMIT = {
  [ACCOUNT_LEVEL.free]: 1000,
  [ACCOUNT_LEVEL.beginner]: 20000,
  [ACCOUNT_LEVEL.advanced]: 30000,
  [ACCOUNT_LEVEL.professional]: 100000,
  [ACCOUNT_LEVEL.admin]: -1,
  [ACCOUNT_LEVEL.unlimited]: -1,
  [ACCOUNT_LEVEL.awebinar]: 6000,
  [ACCOUNT_LEVEL.manual]: 10000,
  [ACCOUNT_LEVEL.operator]: -1,
  [ACCOUNT_LEVEL.support]: -1,
  [ACCOUNT_LEVEL.sales]: -1,
};
export const WEBINAR_HOUR_LIMIT = {
  [ACCOUNT_LEVEL.free]: 6,
  [ACCOUNT_LEVEL.beginner]: 60,
  [ACCOUNT_LEVEL.advanced]: 120,
  [ACCOUNT_LEVEL.professional]: 240,
  [ACCOUNT_LEVEL.admin]: -1,
  [ACCOUNT_LEVEL.unlimited]: -1,
  [ACCOUNT_LEVEL.awebinar]: 0,
  [ACCOUNT_LEVEL.manual]: 0,
  [ACCOUNT_LEVEL.operator]: -1,
  [ACCOUNT_LEVEL.support]: -1,
  [ACCOUNT_LEVEL.sales]: -1,
};

export const STORAGE_LIMIT = {
  [ACCOUNT_LEVEL.free]: 2,
  [ACCOUNT_LEVEL.beginner]: 20,
  [ACCOUNT_LEVEL.advanced]: 40,
  [ACCOUNT_LEVEL.professional]: 60,
  [ACCOUNT_LEVEL.admin]: -1,
  [ACCOUNT_LEVEL.unlimited]: -1,
  [ACCOUNT_LEVEL.awebinar]: 10,
  [ACCOUNT_LEVEL.manual]: 10,
  [ACCOUNT_LEVEL.operator]: -1,
  [ACCOUNT_LEVEL.support]: -1,
  [ACCOUNT_LEVEL.sales]: -1,
};
