import axios from "axios";

class FileUploader {
  async uploadChunk(index) {
    if (!index) {
      index = 0;
    }
    let tries = 0;
    const maxAttempts = 5;
    let success = false;
    while (!success && tries < maxAttempts) {
      try {
        const blob = this.file.slice(this.loaded, this.loaded + this.step);
        const mime = this.file.type || "video/mp4";
        const formData = new FormData();
        formData.append("fileId", this.fileId);
        Object.entries(this.param).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("isLast", this.isLast);
        formData.append("name", this.file.name);
        formData.append("mime", mime);
        formData.append("start", this.start);
        formData.append("position", this.loaded);
        formData.append("index", index);
        formData.append("folderID", this.file.folderID);
        formData.append("file", blob, `${this.file.name}.part.${index}`);

        const response = await axios.post(
          "https://media.aevent.com/api/upload/chunk",
          formData,
          {
            cancelToken: this.cancelToken,
            headers: {
              "Content-Type": "application/octet-stream",
              Authorization: `Bearer ${useCookie("token").value}`,
            },
            onUploadProgress: (e) => {
              const loaded = this.loaded + e.loaded;
              const elapsed = (Date.now() - this.startTime) / 1000;
              const progress = (loaded / this.total) * 100;
              const speed = Math.floor(loaded / elapsed);
              const remaining = Math.floor((this.total - loaded) / speed);

              if (this.callback) {
                this.callback({ progress, speed, remaining });
              }
            },
          }
        );
        success = true;
        this.loaded += this.step;
        if (this.loaded >= this.total) {
          this.loaded = this.total;
        }
        if (this.isLast) {
          if (response.data.message) {
            this.fileId = response.data.message.uuid;
          }
          return response.data.message;
        }
        if (this.loaded + this.step >= this.total) {
          this.isLast = 1;
        }

        return await this.uploadChunk(index + 1);
      } catch (error) {
        if (axios.isCancel(error)) {
          return { canceled: true };
        }
        // Minimize timeouts by halving chunk size.
        this.step = Math.max(Math.floor(this.step / 2), 1024 * 1024)
        tries++;
        if (tries == maxAttempts) {
          throw error;
        }
      }
    }
  }

  upload(token, cancelToken, file, param, start, step, callback, response) {
    this.token = token;
    this.cancelToken = cancelToken;
    this.file = file;
    this.param = param;
    this.userId = this.param.userId;
    this.start = 0;
    this.step = step || 1024 * 1024 * 100;
    this.callback = callback;
    this.response = response;
    this.fileId = `${file.lastModified}-${file.size}`;
    this.startTime = new Date();
    this.loaded = start;
    this.isLast = 0;
    this.total = file.size;

    return this.uploadChunk();
  }
}

export default FileUploader;
